<template>
  <SideNavbar @isShowNavbar="receiveDataFromChild" />
  <div class="animation-duration-item-navbar" :class="{
    'padding-body-show-navbar': isShowNavbar,
    'padding-body-hide-navbar': !isShowNavbar,
  }">
    <div class="mt-1 ps-2">
      <div class="sticky-top bg-white">
        <div class="bg-green-custom ps-2 py-2 text-light font-custom-3 fw-bold">
          Penilaian
        </div>
        <div class="mt-2 d-flex gap-2 justify-content-start pe-2">
          <!-- <button v-if="create_akses == true" class="btn bg-green-custom fs-8 py-1 text-light font-custom-4"
            style="border-radius: 5px" @click="showModal2('modal_import_nilai')">
            <i class="bx bx-import me-1"></i>
            Import nilai
          </button> -->
          <div v-if="create_akses == true ||
            tipe == 'admin' ||
            (tipe = 'pegawai' || create_akses == true)
          ">
            <button class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 d-flex align-items-center"
              style="border-radius: 5px" @click=" show_modal_input_nilai = true;
              showModal2('modal_input_nilai');
              index();
              ">
              <i class="bx bx-list-check me-1"></i>
              Input Nilai
            </button>
          </div>
          <div v-if="create_akses == true ||
            tipe == 'admin' ||
            (tipe = 'pegawai' || create_akses == true)
          ">
            <button class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 d-flex align-items-center"
              style="border-radius: 5px" @click="
                showModal2('modal_input_nilai_tugas_uas'); inputTugasUAS_index();
              ">
              <i class="bx bx-list-check me-1"></i>
              Input Nilai UAS dan Tugas
            </button>

          </div>
          <div v-if="create_akses == true ||
            tipe == 'admin' ||
            (tipe = 'pegawai' || create_akses == true)
          ">
            <button class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 d-flex align-items-center"
              style="border-radius: 5px" @click="
                showModal2('modal_pengaturan_komplain_nilai');
              pengaturan_komplain_nilai_index();
              " v-if="tipe == 'pegawai' || tipe == 'admin'">
              <i class="bx bx-list-check me-1"></i>
              Pengaturan komplain nilai
            </button>
          </div>
          <div v-if="tipe == 'pegawai' || tipe == 'admin'">
            <button class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 d-flex align-items-center"
              style="border-radius: 5px" @click="
                showModal2('modal_persetujuan_nilai');
              persetujuan_nilai_index();
              ">
              <i class="bx bx-list-ol me-1"></i>
              Persetujuan nilai
              <span v-if="jumlah_perbaikan_komplain != 0">
                &nbsp;{{ "(" + jumlah_perbaikan_komplain + ")" }}
              </span>
            </button>
          </div>
          <div v-if="(create_akses == true && tipe == 'dosen') || tipe == 'admin'
          ">
            <button class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 d-flex align-items-center"
              style="border-radius: 5px" @click="
                showModal2('modal_perbaikan_nilai');
              perbaikan_nilai_index();
              ">
              <i class="bx bx-list-ol me-1"></i>
              Perbaikan nilai

              <span v-if="jumlah_setuju_komplain != 0">
                &nbsp;{{ "(" + jumlah_setuju_komplain + ")" }}
              </span>
            </button>
          </div>
          <div>
            <button v-if="(update_akses == true && tipe == 'dosen') || tipe == 'admin'
            " class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 d-flex align-items-center"
              style="border-radius: 5px" @click="
                showModal2('modal_komplain_nilai');
              komplain_nilai_index();
              ">
              <i class="bx bx-list-ol me-1"></i>
              Komplain nilai
              <span v-if="jumlah_pending_komplain != 0">
                &nbsp; {{ "(" + jumlah_pending_komplain + ")" }}
              </span>
            </button>
          </div>

        </div>
        <div class="row mt-2 mb-2 me-0 ">
          <div class="col-2 text-center">
            <select class="form-select" aria-label="Default select example" name="tahun_ajaran" id="tahuna_ajaran"
              v-model="tahunAjaranFilter" @change="
                pageActive = 1;
              offset = 0;
              index();
              ">
              <option value="" selected disabled>- Pilih Tahun Ajaran</option>
              <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                {{ list.nama }}
              </option>
            </select>
          </div>
          <div class="col-2 text-center">
            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
              v-model="prodiFilter" @change="
                pageActive = 1;
              offset = 0;
              index();
              " required>
              <option value="" selected disabled>- Pilih Prodi</option>
              <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                {{ list.nama }}
              </option>
            </select>
          </div>
          <div class="col-2 text-center">
            <select class="form-select" aria-label="Default select example" name="semester" id="semester"
              v-model="semesterFilter" @change="
                pageActive = 1;
              offset = 0;
              index();
              " required :disabled="prodiFilter == ''">
              <option value="" selected>- Pilih Semester</option>
              <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                {{ list.nama }}
              </option>
            </select>
          </div>
          <div class="col-2 text-center">
            <select class="form-select" aria-label="Default select example" name="kelas" id="kelas"
              v-model="stambukFilter" @change="
                pageActive = 1;
              offset = 0;
              index();
              " required :disabled="prodiFilter == ''">
              <option value="" selected>- Pilih Stambuk</option>
              <option v-for="list in stambukList" :key="list.nama" :value="list.id">
                {{ list.nama }}
              </option>
            </select>
          </div>
          <div class="col-2 text-center">
            <select class="form-select" aria-label="Default select example" name="kelas" id="kelas"
              v-model="kelasFilter" @change="
                pageActive = 1;
              offset = 0;
              index();
              " required :disabled="stambukFilter == ''">
              <option value="" selected>- Pilih Kelas</option>
              <option v-for="list in kelasList" :key="list.nama" :value="list.id">
                {{ list.nama }}
              </option>
            </select>
          </div>

        </div>
        <div class="mt-2 d-flex gap-2 justify-content-start">
          <div>
            <select class="form-select" aria-label="Default select example" name="semester" id="semester"
              v-model="limit" @change="
                pageActive = 1;
              offset = 0;
              index();
              " required>
              <option v-for="list in limitList" :key="list.value" :value="list.value" @change="index()">
                {{ list.value }}
              </option>
            </select>
          </div>
          <div class="">
            <nav aria-label="Page navigation example">
              <ul class="pagination" ref="pagi">
                <li class="page-item">
                  <span class="page-link" @click="
if (pageActive != 1) {
                    pageActive--;
                    offset = offset - limit;
                    index('previous');
                  }
                    " aria-label="Previous" style="cursor: pointer">
                    <span aria-hidden="true">&laquo;</span>
                  </span>
                </li>
                <li v-for="list in paginglist" :key="list.nama" :value="list.value" class="page-item" :class="{
                  active: list.nama == pageActive,
                  'd-none': list.show == false,
                }">
                  <span class="page-link text-black" @click="
if (list.disabled != true) pageActive = list.nama;
                    offset = list.value;
                    index();
                    " style="cursor: pointer">{{ list.nama }}</span>
                </li>
                <li class="page-item">
                  <span class="page-link" @click="
if (pageActive != totalPages) {
                    pageActive++;
                    offset = offset + limit;
                    index('next');
                  }
                    " aria-label="Next" style="cursor: pointer">
                    <span aria-hidden="true">&raquo;</span>
                  </span>
                </li>
              </ul>
            </nav>
          </div>
          <div>
            <input type="text" v-model="query" @change="
              pageActive = 1;
            offset = 0;
            index();
            " class="form-control" placeholder="Cari nama, nim " />
          </div>
        </div>
      </div>

      <div class="d-flex flex-column gap-2">
        <!-- <div class="fs-8 fw-bold font-custom-2 font-gray-custom">
                        >Desktop/Pengguna
                    </div> -->
        <div class="border rounded-3 me-2 font-custom-1 mb-5" style="
            overflow-x: scroll;
            z-index: 0;
            overflow-y: scroll;
            max-height: 60vh !important;
          ">
          <table class="table mb-0 bg-transparent table-borderless" style="">
            <thead class="sticky-top" style="top: 0px">
              <tr>
                <th scope="col" class="fs-7">No</th>
                <th scope="col" class="font-custom-1 fs-7">NIM</th>
                <th scope="col" class="font-custom-1 fs-7">Nama Lengkap</th>
                <!-- <th scope="col" class="font-custom-1 fs-7">Kelas</th>
                                <th scope="col" class="font-custom-1 fs-7">Prodi</th> -->
                <th scope="col" class="fs-7">Aksi</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(record, index) in records" :key="record.id" :ref="record.id" class="border-bottom">
                <th scope="row" class="bg-transparent">{{ index + 1 }}</th>
                <td>{{ record.nim_mahasiswa }}</td>
                <td>{{ capitalizeWords(record.nama_lengkap) }}</td>
                <!-- <td>{{ record.kelas }}</td>
                                <td>{{ record.prodi }}</td> -->
                <td class="bg-transparent">
                  <div class="d-flex gap-2">
                    <!-- <button class="btn btn-primary btn-sm fs-8" v-on:click="editRecord(record)"
                                            data-bs-toggle="modal" data-bs-target="#modal_berita_acara"><i
                                                class='bx bxs-file fs-8'></i></button> -->
                    <button class="btn btn-primary btn-sm fs-8" v-on:click="editRecord(record)">
                      <i class="bx bxs-show fs-8"></i>
                    </button>
                  </div>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal_mata_kuliah_nilai" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_mata_kuliah_nilai">
    <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header bg-green-custom text-light">
          <h5 class="modal-title" id="exampleModalScrollableTitle">
            Nilai {{ currentRecord.nama_lengkap }}
          </h5>
          <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
            ref="close_modal_mata_kuliah_nilai"></button>
          <button type="button" class="btn bg-transparent text-light border-0">
            <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_mata_kuliah_nilai')" />
          </button>
        </div>

        <div class="modal-body">
          <div class="border mt-1 rounded-3 font-custom-1 mb-5" style="overflow-x: auto; z-index: 0">
            <table class="table mb-0 bg-transparent table-borderless" style="">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Nama Mata Kuliah</th>
                  <th>status</th>
                  <!-- <th>Finalisasi UTS</th>
                                    <th>Finalisasi UAS</th>
                                    <th>Finalisasi Tugas</th> -->
                  <th>Kehadiran</th>
                  <th>Tugas</th>
                  <th>Nilai UTS</th>
                  <th>Nilai UAS</th>
                  <th>Bobot Kehadiran</th>
                  <th>Bobot Tugas</th>
                  <th>Bobot UTS</th>
                  <th>Bobot UAS</th>
                  <th>Nilai mutu</th>
                  <th>Remedial</th>
                  <th>Semester Pendek</th>
                  <th>Nilai huruf</th>
                  <!-- <th>Nilai UTS (Susulan)</th>
                                    <th>Nilai UAS (Susulan)</th> -->
                  <!-- <th>Aksi</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(record, index) in mataKuliahNilaiRecords" :key="record.id" :ref="record.id"
                  class="border-bottom">
                  <th scope="row" class="bg-transparent">{{ index + 1 }}</th>
                  <td>{{ record.nama }}</td>
                  <td>{{ record.status_input_nilai }}</td>

                  <td>{{ record.kehadiran }}</td>
                  <td>
                    <span v-if="record.tugas == '-1'">-</span>
                    <span v-if="record.tugas != '-1'">{{ record.tugas }}</span>
                  </td>
                  <td>
                    <span v-if="record.uts == '-1' && record.susulan_uts == '-1'">-</span>
                    <span v-if="record.uts != '-1'">{{ record.uts }}</span>
                    <span v-if="record.susulan_uts != '-1'">{{
                      record.susulan_uts
                      }}</span>
                  </td>
                  <td>
                    <span v-if="record.uas == '-1' && record.susulan_uas == '-1'">-</span>
                    <span v-if="record.uas != '-1'">{{ record.uas }}</span>
                    <span v-if="record.susulan_uas != '-1'">{{
                      record.susulan_uas
                      }}</span>
                  </td>
                  <td>{{ record.bobot_kehadiran }}</td>
                  <td>{{ record.bobot_tugas }}</td>
                  <td>{{ record.bobot_uts }}</td>
                  <td>{{ record.bobot_uas }}</td>
                  <td>{{ record.nilai_mutu }} </td>
                  <td><span v-if="record.remedial == '-1'">-</span>
                    <span v-else>{{ record.remedial }}</span>
                  </td>
                  <td><span v-if="record.semester_pendek == '-1'">-</span>
                    <span v-else>{{ record.semester_pendek }}</span>
                  </td>
                  <td>{{ record.nilai_huruf }}</td>
                  <!-- <td><span v-if="record.susulan_uts == '-1'">-</span> <span
                                            v-if="record.susulan_uts != '-1'">{{
                                                            record.susulan_uts }}</span></td>
                                    <td><span v-if="record.susulan_uas == '-1'">-</span> <span
                                            v-if="record.susulan_uas != '-1'">{{
                                                            record.susulan_uas }}</span></td> -->
                  <!-- <td class="bg-transparent">
                                        <div class="d-flex gap-2" v-if="record.status_input_nilai == 'nilai diinput'">
                                            <button v-if="update_akses == true" class="btn btn-warning btn-sm fs-8"
                                                @click="editRecordNilai(record);"><i
                                                    class='bx bxs-message-square-edit fs-8'></i></button>
                                        </div>
                                    </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="hideModal2('modal_mata_kuliah_nilai')">
            Tutup
          </button>
          <!-- <button type="submit" class="btn bg-green-custom text-light">
                        Simpan </button> -->
        </div>
      </div>
    </div>
  </div>

  <form @submit.prevent="importNilaiMataKuliah">
    <div class="modal fade" id="modal_import_nilai" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_import_nilai">
      <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header bg-green-custom text-light">
            <h5 class="modal-title" id="exampleModalScrollableTitle">
              Import Nilai
            </h5>

            <button type="button" class="btn bg-transparent text-light border-0">
              <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_import_nilai')" />
            </button>
          </div>
          <div class="modal-body" style="
              overflow-y: auto;
              height: 100vh !important;
              overflow-x: hidden;
              width: 100vw !important;
            ">
            <div>
              <div class="mb-3">
                <label for="tahun_ajaran" class="form-label">
                  Tahun Ajaran
                </label>
                <select class="form-select" aria-label="Default select example" name="tahun_ajaran" id="tahuna_ajaran"
                  v-model="tahunAjaranFilter" @change="
                    pageActive = 1;
                  offset = 0;
                  index();
                  ">
                  <option value="" selected disabled>
                    - Pilih Tahun Ajaran
                  </option>
                  <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                    {{ list.nama }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="prodi" class="form-label"> Prodi </label>
                <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                  v-model="prodiFilter" @change="
                    pageActive = 1;
                  offset = 0;
                  index();
                  " required>
                  <option value="" selected disabled>- Pilih Prodi</option>
                  <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                    {{ list.nama }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="semester" class="form-label"> Semester </label>
                <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                  v-model="semesterFilter" @change="
                    pageActive = 1;
                  offset = 0;
                  index();
                  " required :disabled="prodiFilter == ''">
                  <option value="" selected>- Pilih Semester</option>
                  <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                    {{ list.nama }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="stambuk" class="form-label"> Stambuk </label>
                <select class="form-select" aria-label="Default select example" name="kelas" id="kelas"
                  v-model="stambukFilter" @change="
                    pageActive = 1;
                  offset = 0;
                  index();
                  " required :disabled="prodiFilter == ''">
                  <option value="" selected>- Pilih Stambuk</option>
                  <option v-for="list in stambukList" :key="list.nama" :value="list.id">
                    {{ list.nama }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="kelas" class="form-label"> Kelas </label>
                <select class="form-select" aria-label="Default select example" name="kelas" id="kelas"
                  v-model="kelasFilter" @change="
                    pageActive = 1;
                  offset = 0;
                  index();
                  " required :disabled="stambukFilter == ''">
                  <option value="" selected>- Pilih Kelas</option>
                  <option v-for="list in kelasList" :key="list.nama" :value="list.id">
                    {{ list.nama }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="mata_kuliah" class="form-label">
                  Mata Kuliah
                </label>
                <select class="form-select" aria-label="Default select example" name="mata_kuliah" id="mata_kuliah"
                  v-model="mataKuliahFilter" @change="
                    pageActive = 1;
                  offset = 0;
                  index();
                  " required :disabled="kelasFilter == ''">
                  <option value="" selected>- Pilih Mata Kuliah</option>
                  <option v-for="list in mataKuliahList" :key="list.nama" :value="list.id">
                    {{ list.nama }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="tipe_import" class="form-label">
                  Pilih nilai
                </label>
                <select class="form-select" aria-label="Default select example" name="mata_kuliah" id="mata_kuliah"
                  v-model="tipe_import" required :disabled="kelasFilter == ''">
                  <option value="" selected disabled>- Pilih Nilai</option>
                  <option value="uts" selected>UTS</option>
                  <!-- <option value="uas_tugas" selected>UAS & TUGAS</option> -->

                  <option value="susulan_uts" selected>SUSULAN (UTS)</option>
                  <option value="remedial" selected>REMEDIAL</option>
                  <option value="susulan_uas" selected>SUSULAN (UAS)</option>
                  <option value="semester_pendek" selected>SEMESTER PENDEK</option>
                </select>
              </div>

              <div class="mb-3" v-if="mataKuliahFilter != '' && tipe_import != ''">
                <label for="file" class="form-label"> file </label>
                <div class="d-flex justify-content-start align-items-center gap-2">
                  <div>
                    <div class="h-100 text-center">
                      <button class="btn text-light bg-green-custom" type="button" @click="eksportNilaiMataKuliah()">
                        <i class="bx bx-export me- 2"></i> Template
                      </button>
                    </div>
                  </div>
                  <div>
                    <input type="file" name="file_input" class="form-control" id="file_input" required />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hideModal2('modal_import_nilai')">
              Tutup
            </button>
            <button type="submit" class="btn bg-green-custom text-light">
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

  <form @submit.prevent="nilaiUpdate">
    <div class="modal fade" id="modal_mata_kuliah_nilai_edit" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_mata_kuliah_nilai_edit">
      <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header bg-green-custom text-light">
            <h5 class="modal-title" id="exampleModalScrollableTitle">
              {{ currentRecord.nama_lengkap }} - {{ currentRecordNilai.nama }}
            </h5>
            <button type="button" class="btn bg-transparent text-light border-0">
              <img src="@/assets/icon/close.png" alt="" width="30"
                @click="hideModal2('modal_mata_kuliah_nilai_edit')" />
            </button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="Tugas" class="form-label"> Tugas </label>
              <input type="number" step="0.01" v-model="currentRecordNilai.tugas" class="form-control" />
            </div>
            <div class="mb-3">
              <label for="UTS" class="form-label"> UTS </label>
              <input type="number" step="0.01" v-model="currentRecordNilai.uts" class="form-control" />
            </div>
            <div class="mb-3">
              <label for="UAS" class="form-label"> UAS </label>
              <input type="number" step="0.01" v-model="currentRecordNilai.uas" class="form-control" />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hideModal2('modal_mata_kuliah_nilai_edit')">
              Tutup
            </button>
            <button v-if="update_akses == true &&
              currentRecordNilai.status_nilai == 'PENDING'
            " class="btn bg-green-custom text-light" @click="finalisasi = 'YA'">
              Finalisasi
            </button>
            <button v-if="update_akses == true &&
              currentRecordNilai.status_nilai == 'PENDING'
            " class="btn bg-green-custom text-light" @click="finalisasi = 'TIDAK'">
              Finalisasi
            </button>
            <button type="submit" class="btn bg-primary text-light" v-if="update_akses == true">
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <form @submit.prevent="finalisasiNilai">
    <div class="modal fade" id="modal_finalisasi" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_finalisasi">
      <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header bg-green-custom text-light">
            <h5 class="modal-title" id="exampleModalScrollableTitle">
              Finalisasi Nilai UTS
            </h5>
            <button type="button" class="btn bg-transparent text-light border-0">
              <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_finalisasi')" />
            </button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="tahun_ajaran" class="form-label">
                Tahun Ajaran
              </label>
              <select class="form-select" aria-label="Default select example" name="tahun_ajaran" id="tahuna_ajaran"
                v-model="tahunAjaranFilter" @change="
                  pageActive = 1;
                offset = 0;
                index();
                ">
                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label for="prodi" class="form-label"> Prodi </label>
              <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                v-model="prodiFilter" @change="
                  pageActive = 1;
                offset = 0;
                index();
                " required>
                <option value="" selected disabled>- Pilih Prodi</option>
                <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label for="semester" class="form-label"> Semester </label>
              <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                v-model="semesterFilter" @change="
                  pageActive = 1;
                offset = 0;
                index();
                " required :disabled="prodiFilter == ''">
                <option value="" selected>- Pilih Semester</option>
                <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label for="stambuk" class="form-label"> Stambuk </label>
              <select class="form-select" aria-label="Default select example" name="kelas" id="kelas"
                v-model="stambukFilter" @change="
                  pageActive = 1;
                offset = 0;
                index();
                " required :disabled="prodiFilter == ''">
                <option value="" selected>- Pilih Stambuk</option>
                <option v-for="list in stambukList" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label for="kelas" class="form-label"> Kelas </label>
              <select class="form-select" aria-label="Default select example" name="kelas" id="kelas"
                v-model="kelasFilter" @change="
                  pageActive = 1;
                offset = 0;
                index();
                " required :disabled="stambukFilter == ''">
                <option value="" selected>- Pilih Kelas</option>
                <option v-for="list in kelasList" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label for="mata_kuliah" class="form-label"> Mata Kuliah </label>
              <select class="form-select" aria-label="Default select example" name="mata_kuliah" id="mata_kuliah"
                v-model="mataKuliahFilter" @change="
                  pageActive = 1;
                offset = 0;
                index();
                " required :disabled="kelasFilter == ''">
                <option value="" selected>- Pilih Mata Kuliah</option>
                <option v-for="list in mataKuliahList" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label for="tipe_import" class="form-label"> Pilih nilai (Finalisasi nilai uas dan tugas ada di tombol
                input nilai uas tugas)</label>
              <select class="form-select" aria-label="Default select example" name="mata_kuliah" id="mata_kuliah"
                v-model="tipe_import" required :disabled="kelasFilter == ''" @change="
                  pageActive = 1;
                offset = 0;
                index();
                ">
                <option value="" selected disabled>- Pilih Nilai</option>
                <option value="uts" selected>UTS</option>
                <!-- <option value="uas" selected>UAS</option> -->
                <!-- <option value="tugas" selected>Tugas</option> -->
                <!-- <option value="uas_tugas" selected>UAS & TUGAS</option> -->
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hideModal2('modal_finalisasi')">
              Tutup
            </button>
            <button v-if="(update_akses == true && status_finalisasi_cek.status_finalisasi_dosen == 'PENDING' && tipe == 'dosen')
            " class="btn bg-green-custom text-light" @click="finalisasi = 'YA'">
              Finalisasi Dosen
            </button>


            <button v-if="(update_akses == true && status_finalisasi_cek == 'FINAL' && tipe == 'pegawai') || (tipe == 'admin')
            " class="btn bg-danger text-light" @click="finalisasi = 'TIDAK'">
              Batalkan Finalisasi
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="modal fade" id="modal_berita_acara_delete" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_berita_acara_delete">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header bg-green-custom text-light">
          <h5 class="modal-title" id="exampleModalScrollableTitle">
            Hapus Nilai
          </h5>
          <button type="button" class="btn bg-transparent text-light border-0">
            <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_berita_acara_delete')" />
          </button>
        </div>
        <div class="modal-body">
          <div>
            Apakah Anda yakin ingin menghapus data ini? data tidak dapat
            dikembalikan
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="hideModal2('modal_berita_acara_delete')">
            Tidak, tetap simpan disini
          </button>
          <button type="button" class="btn bg-danger text-light" @click="deleteData">
            Ya,Hapus
          </button>
        </div>
      </div>
    </div>
  </div>



  <div class="modal fade" id="modal_komplain_nilai" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_komplain_nilai">
    <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header bg-green-custom text-light">
          <h5 class="modal-title" id="exampleModalScrollableTitle">
            List Komplain Nilai
          </h5>
          <button type="button" class="btn bg-transparent text-light border-0">
            <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_komplain_nilai')" />
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="tahun_ajaran" id="tahuna_ajaran"
                v-model="tahunAjaranFilterKomplain" @change="
                  pageActive = 1;
                offset = 0;
                komplain_nilai_index();
                ">
                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                <option v-for="list in tahunAjaranListKomplain" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                v-model="prodiFilterKomplain" @change="
                  pageActive = 1;
                offset = 0;
                komplain_nilai_index();
                " required>
                <option value="" selected disabled>- Pilih Prodi</option>
                <option v-for="list in prodiListKomplain" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                v-model="semesterFilterKomplain" @change="
                  pageActive = 1;
                offset = 0;
                komplain_nilai_index();
                " required :disabled="prodiFilterKomplain == ''">
                <option value="" selected>- Pilih Semester</option>
                <option v-for="list in semesterListKomplain" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="kurikulum" id="kurikulum"
                v-model="kurikulumFilterKomplain" @change="
                  pageActive = 1;
                offset = 0;
                komplain_nilai_index();
                " required :disabled="prodiFilterKomplain == ''">
                <option value="" selected disabled>- Pilih Kurikulum</option>
                <option v-for="list in kurikulumListKomplain" :key="list.id" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>

            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                v-model="MataKuliahFilterKomplain" @change="
                  pageActive = 1;
                offset = 0;
                komplain_nilai_index();
                " required :disabled="semesterFilterKomplain == ''">
                <option value="" selected>- Pilih Mata Kuliah</option>
                <option v-for="list in mataKuliahListKomplain" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                v-model="tipeFilterKomplain" @change="
                  pageActive = 1;
                offset = 0;
                komplain_nilai_index();
                " required :disabled="semesterFilterKomplain == ''">
                <option value="" selected>- Pilih Tipe</option>
                <option v-for="list in tipeListKomplain" :key="list.id" :value="list.nama" @change="index()">
                  {{ list.nama.toUpperCase() }}
                </option>
              </select>
            </div>
          </div>
          <div class="">
            <div class="d-flex align-items-start justify-content-start mt-2 gap-2">
              <div>
                <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                  v-model="limitKomplain" @change="
                    pageActiveKomplain = 1;
                  offsetKomplain = 0;
                  index();
                  " required>
                  <option v-for="list in limitList" :key="list.value" :value="list.value" @change="index()">
                    {{ list.value }}
                  </option>
                </select>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <nav aria-label="Page navigation example">
                  <ul class="pagination" ref="pagi">
                    <li class="page-item">
                      <span class="page-link" @click="
if (pageActiveKomplain != 1) {
                        pageActiveKomplain--;
                        offsetKomplain = offsetKomplain - limitKomplain;
                        komplain_nilai_index('previous');
                      }
                        " aria-label="Previous" style="cursor: pointer">
                        <span aria-hidden="true">&laquo;</span>
                      </span>
                    </li>
                    <li v-for="list in paginglistKomplain" :key="list.nama" :value="list.value" class="page-item"
                      :class="{
                        active: list.nama == pageActiveKomplain,
                        'd-none': list.show == false,
                      }">
                      <span class="page-link text-black" @click="
if (list.disabled != true)
                        pageActiveKomplain = list.nama;
                        offsetKomplain = list.value;
                        komplain_nilai_index();
                        " style="cursor: pointer">{{ list.nama }}</span>
                    </li>
                    <li class="page-item">
                      <span class="page-link" @click="
if (pageActiveKomplain != totalPagesKomplain) {
                        pageActiveKomplain++;
                        offsetKomplain = offsetKomplain + limitKomplain;
                        komplain_nilai_index('next');
                      }
                        " aria-label="Next" style="cursor: pointer">
                        <span aria-hidden="true">&raquo;</span>
                      </span>
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="w-25">
                <input type="text" v-model="queryKomplain" @change="
                  pageActiveKomplain = 1;
                offsetKomplain = 0;
                komplain_nilai_index();
                " class="form-control" placeholder="Cari NIM, Nama Lengkap" />
              </div>
            </div>
          </div>

          <div class="border mt-1 rounded-3 font-custom-1 mb-5" style="overflow-x: auto; z-index: 0">
            <table class="table mb-0 bg-transparent table-borderless" style="">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Tanggal</th>
                  <th>NIM</th>
                  <th>Nama lengkap</th>
                  <th>Waktu Kuliah</th>

                  <th>Mata kuliah</th>
                  <th>Dosen Pengajar</th>
                  <!-- <th>Persetujuan</th> -->
                  <th>Tipe</th>
                  <th>Alasan</th>
                  <th>Status</th>
                  <th>Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(record, index) in recordsKomplain" :key="record.id" :ref="record.id" class="border-bottom">
                  <th scope="row" class="bg-transparent">{{ index + 1 }}</th>
                  <td>{{ DateFormat(record.tanggal) }}</td>
                  <td>{{ record.nim_mahasiswa }}</td>
                  <td>{{ record.nama_lengkap }}</td>
                  <td>{{ record.waktu_kuliah }}</td>

                  <td>{{ record.mata_kuliah }}</td>
                  <td>
                    <span v-if="record.dosen_pengajar_1 != null">
                      {{ record.dosen_pengajar_1 }}
                    </span>
                    <span v-if="record.dosen_pengajar_2 != null"> & </span>
                    <span v-if="record.dosen_pengajar_2 != null">
                      {{ record.dosen_pengajar_2 }}
                    </span>
                  </td>
                  <td>
                    {{ record.tipe.toUpperCase() }}
                  </td>
                  <td>{{ record.alasan }}</td>
                  <td>{{ record.status_persetujuan }}</td>
                  <td class="bg-transparent">
                    <div class="d-flex gap-2" v-if="record.status_persetujuan == 'PENDING'">
                      <button v-if="update_akses == true" class="btn btn-danger btn-sm fs-8" @click="
                        id_komplain_nilai = record.id;
                      persetujuan_komplain_nilai = 'TOLAK';
                      editRecordKomplain(record);
                      showModal2('modal_setujui');
                      ">
                        <i class="bx bx-x fs-8"></i>
                      </button>
                      <button v-if="update_akses == true" class="btn btn-success btn-sm fs-8" @click="
                        id_komplain_nilai = record.id;
                      persetujuan_komplain_nilai = 'SETUJU';
                      editRecordKomplain(record);
                      showModal2('modal_setujui');
                      ">
                        <i class="bx bx-check fs-8"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="hideModal2('modal_komplain_nilai')">
            Tutup
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal_setujui" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
    aria-hidden="true" ref="modal_setujui">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header bg-green-custom text-light">
          <h5 class="modal-title" id="exampleModalScrollableTitle">
            {{ persetujuan_komplain_nilai }}
          </h5>
          <button type="button" class="btn bg-transparent text-light border-0">
            <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_setujui')" />
          </button>
        </div>
        <div class="modal-body">
          <div>Apakah Anda yakin ? data tidak dapat dikembalikan</div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="hideModal2('modal_setujui')">
            Tidak, Batal
          </button>
          <button type="button" class="btn bg-danger text-light" @click="komplain_nilai_update">
            Ya
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal_perbaikan_nilai" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_perbaikan_nilai">
    <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header bg-green-custom text-light">
          <h5 class="modal-title" id="exampleModalScrollableTitle">
            List Perbaiki Nilai
          </h5>
          <button type="button" class="btn bg-transparent text-light border-0">
            <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_perbaikan_nilai')" />
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="tahun_ajaran" id="tahuna_ajaran"
                v-model="tahunAjaranFilterPerbaikan" @change="
                  pageActive = 1;
                offset = 0;
                perbaikan_nilai_index();
                ">
                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                <option v-for="list in tahunAjaranListPerbaikan" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                v-model="prodiFilterPerbaikan" @change="
                  pageActive = 1;
                offset = 0;
                perbaikan_nilai_index();
                " required>
                <option value="" selected disabled>- Pilih Prodi</option>
                <option v-for="list in prodiListPerbaikan" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                v-model="semesterFilterPerbaikan" @change="
                  pageActive = 1;
                offset = 0;
                perbaikan_nilai_index();
                " required :disabled="prodiFilterPerbaikan == ''">
                <option value="" selected>- Pilih Semester</option>
                <option v-for="list in semesterListPerbaikan" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="kurikulum" id="kurikulum"
                v-model="kurikulumFilterPerbaikan" @change="
                  pageActive = 1;
                offset = 0;
                perbaikan_nilai_index();
                " required :disabled="prodiFilterPerbaikan == ''">
                <option value="" selected disabled>- Pilih Kurikulum</option>
                <option v-for="list in kurikulumListPerbaikan" :key="list.id" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>

            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                v-model="MataKuliahFilterPerbaikan" @change="
                  pageActive = 1;
                offset = 0;
                perbaikan_nilai_index();
                " required :disabled="semesterFilterPerbaikan == ''">
                <option value="" selected>- Pilih Mata Kuliah</option>
                <option v-for="list in mataKuliahListPerbaikan" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                v-model="tipeFilterPerbaikan" @change="
                  pageActive = 1;
                offset = 0;
                perbaikan_nilai_index();
                " required :disabled="semesterFilterPerbaikan == ''">
                <option value="" selected>- Pilih Tipe</option>
                <option v-for="list in tipeListPerbaikan" :key="list.id" :value="list.nama" @change="index()">
                  {{ list.nama.toUpperCase() }}
                </option>
              </select>
            </div>
          </div>
          <div class="">
            <div class="d-flex align-items-start justify-content-start mt-2 gap-2">
              <div>
                <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                  v-model="limitPerbaikan" @change="
                    pageActivePerbaikan = 1;
                  offsetPerbaikan = 0;
                  perbaikan_nilai_index();
                  " required>
                  <option v-for="list in limitList" :key="list.value" :value="list.value"
                    @change="perbaikan_nilai_index()">
                    {{ list.value }}
                  </option>
                </select>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <nav aria-label="Page navigation example">
                  <ul class="pagination" ref="pagi">
                    <li class="page-item">
                      <span class="page-link" @click="
if (pageActivePerbaikan != 1) {
                        pageActivePerbaikan--;
                        offsetPerbaikan = offsetPerbaikan - limitPerbaikan;
                        perbaikan_nilai_index('previous');
                      }
                        " aria-label="Previous" style="cursor: pointer">
                        <span aria-hidden="true">&laquo;</span>
                      </span>
                    </li>
                    <li v-for="list in paginglistPerbaikan" :key="list.nama" :value="list.value" class="page-item"
                      :class="{
                        active: list.nama == pageActivePerbaikan,
                        'd-none': list.show == false,
                      }">
                      <span class="page-link text-black" @click="
if (list.disabled != true)
                        pageActivePerbaikan = list.nama;
                        offsetPerbaikan = list.value;
                        perbaikan_nilai_index();
                        " style="cursor: pointer">{{ list.nama }}</span>
                    </li>
                    <li class="page-item">
                      <span class="page-link" @click="
if (pageActivePerbaikan != totalPagesPerbaikan) {
                        pageActivePerbaikan++;
                        offsetPerbaikan = offsetPerbaikan + limitPerbaikan;
                        perbaikan_nilai_index('next');
                      }
                        " aria-label="Next" style="cursor: pointer">
                        <span aria-hidden="true">&raquo;</span>
                      </span>
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="w-25">
                <input type="text" v-model="queryPerbaikan" @change="
                  pageActivePerbaikan = 1;
                offsetPerbaikan = 0;
                perbaikan_nilai_index();
                " class="form-control" placeholder="Cari NIM, Nama Lengkap" />
              </div>
            </div>
          </div>

          <div class="border mt-1 rounded-3 font-custom-1 mb-5" style="overflow-x: auto; z-index: 0">
            <table class="table mb-0 bg-transparent table-borderless" style="">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Tanggal</th>
                  <th>NIM</th>
                  <th>Nama lengkap</th>
                  <th>Waktu Kuliah</th>

                  <th>Mata kuliah</th>
                  <th>Dosen Pengajar</th>
                  <!-- <th>Persetujuan</th> -->
                  <th>Tipe</th>
                  <th>Alasan</th>
                  <th>Status</th>
                  <th>Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(record, index) in recordsPerbaikan" :key="record.id" :ref="record.id" class="border-bottom">
                  <th scope="row" class="bg-transparent">{{ index + 1 }}</th>
                  <td>{{ DateFormat(record.tanggal) }}</td>
                  <td>{{ record.nim_mahasiswa }}</td>
                  <td>{{ record.nama_lengkap }}</td>
                  <td>{{ record.waktu_kuliah }}</td>

                  <td>{{ record.mata_kuliah }}</td>
                  <td>
                    <span v-if="record.dosen_pengajar_1 != null">
                      {{ record.dosen_pengajar_1 }}
                    </span>
                    <span v-if="record.dosen_pengajar_2 != null"> & </span>
                    <span v-if="record.dosen_pengajar_2 != null">
                      {{ record.dosen_pengajar_2 }}
                    </span>
                  </td>
                  <td>
                    {{ record.tipe.toUpperCase() }}
                  </td>
                  <td>{{ record.alasan }}</td>
                  <td>{{ record.status_persetujuan }}</td>
                  <td class="bg-transparent">
                    <div class="d-flex gap-2" v-if="record.status_persetujuan == 'SETUJU' ||
                      record.status_persetujuan == 'PERBAIKAN_KEMBALI'
                    ">
                      <button v-if="create_akses == true" class="btn btn-warning btn-sm fs-8" @click="
                        editRecordPerbaikan(record);
                      showModal2('modal_perbaikan_nilai_update');
                      ">
                        <i class="bx bx-edit fs-8"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="hideModal2('modal_perbaikan_nilai')">
            Tutup
          </button>
        </div>
      </div>
    </div>
  </div>

  <form @submit.prevent="perbaikan_nilai_update">
    <div class="modal fade" id="modal_perbaikan_nilai_update" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_perbaikan_nilai_update">
      <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header bg-green-custom text-light">
            <h5 class="modal-title" id="exampleModalScrollableTitle">
              {{ currentRecordPerbaikan.nama_lengkap }} -
              {{ currentRecordPerbaikan.mata_kuliah }}
            </h5>
            <button type="button" class="btn bg-transparent text-light border-0">
              <img src="@/assets/icon/close.png" alt="" width="30"
                @click="hideModal2('modal_perbaikan_nilai_update')" />
            </button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="nama_lengkap" class="form-label"> Alasan </label>
              <textarea class="form-control" v-model="currentRecordPerbaikan.alasan" />
            </div>
            <div class="mb-3">
              <label for="label_nilai" class="form-label">
                Nilai {{ currentRecordPerbaikan.tipe.toUpperCase() }} lama
              </label>
              <input type="number" step="0.01" v-model="currentRecordPerbaikan.nilai_uts" class="form-control"
                v-if="currentRecordPerbaikan.tipe == 'uts'" disabled />
              <input type="number" step="0.01" v-model="currentRecordPerbaikan.nilai_uas" class="form-control"
                v-if="currentRecordPerbaikan.tipe == 'uas'" disabled />
            </div>
            <div class="mb-3">
              <label for="label_nilai" class="form-label">
                Nilai {{ currentRecordPerbaikan.tipe.toUpperCase() }} baru
              </label>
              <input type="number" step="0.01" v-model="currentRecordPerbaikan.uts" class="form-control"
                v-if="currentRecordPerbaikan.tipe == 'uts'" />
              <input type="number" step="0.01" v-model="currentRecordPerbaikan.uas" class="form-control"
                v-if="currentRecordPerbaikan.tipe == 'uas'" />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hideModal2('modal_perbaikan_nilai_update')">
              Tutup
            </button>
            <button type="submit" class="btn bg-primary text-light" v-if="create_akses == true">
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="modal fade" id="modal_persetujuan_nilai" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_persetujuan_nilai">
    <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header bg-green-custom text-light">
          <h5 class="modal-title" id="exampleModalScrollableTitle">
            List Persetujuan Nilai
          </h5>
          <button type="button" class="btn bg-transparent text-light border-0">
            <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_persetujuan_nilai')" />
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="tahun_ajaran" id="tahuna_ajaran"
                v-model="tahunAjaranFilterPersetujuan" @change="
                  pageActive = 1;
                offset = 0;
                persetujuan_nilai_index();
                ">
                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                <option v-for="list in tahunAjaranListPersetujuan" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                v-model="prodiFilterPersetujuan" @change="
                  pageActive = 1;
                offset = 0;
                persetujuan_nilai_index();
                " required>
                <option value="" selected disabled>- Pilih Prodi</option>
                <option v-for="list in prodiListPersetujuan" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                v-model="semesterFilterPersetujuan" @change="
                  pageActive = 1;
                offset = 0;
                persetujuan_nilai_index();
                " required :disabled="prodiFilterPersetujuan == ''">
                <option value="" selected>- Pilih Semester</option>
                <option v-for="list in semesterListPersetujuan" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="kurikulum" id="kurikulum"
                v-model="kurikulumFilterPersetujuan" @change="
                  pageActive = 1;
                offset = 0;
                persetujuan_nilai_index();
                " required :disabled="prodiFilterPersetujuan == ''">
                <option value="" selected disabled>- Pilih Kurikulum</option>
                <option v-for="list in kurikulumListPersetujuan" :key="list.id" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>

            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                v-model="MataKuliahFilterPersetujuan" @change="
                  pageActive = 1;
                offset = 0;
                persetujuan_nilai_index();
                " required :disabled="semesterFilterPersetujuan == ''">
                <option value="" selected>- Pilih Mata Kuliah</option>
                <option v-for="list in mataKuliahListPersetujuan" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                v-model="tipeFilterPersetujuan" @change="
                  pageActive = 1;
                offset = 0;
                persetujuan_nilai_index();
                " required :disabled="semesterFilterPersetujuan == ''">
                <option value="" selected>- Pilih Tipe</option>
                <option v-for="list in tipeListPersetujuan" :key="list.id" :value="list.nama" @change="index()">
                  {{ list.nama.toUpperCase() }}
                </option>
              </select>
            </div>
          </div>
          <div class="">
            <div class="d-flex align-items-start justify-content-start mt-2 gap-2">
              <div>
                <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                  v-model="limitPersetujuan" @change="
                    pageActivePersetujuan = 1;
                  offsetPersetujuan = 0;
                  persetujuan_nilai_index();
                  " required>
                  <option v-for="list in limitList" :key="list.value" :value="list.value"
                    @change="persetujuan_nilai_index()">
                    {{ list.value }}
                  </option>
                </select>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <nav aria-label="Page navigation example">
                  <ul class="pagination" ref="pagi">
                    <li class="page-item">
                      <span class="page-link" @click="
if (pageActivePersetujuan != 1) {
                        pageActivePersetujuan--;
                        offsetPersetujuan =
                          offsetPersetujuan - limitPersetujuan;
                        persetujuan_nilai_index('previous');
                      }
                        " aria-label="Previous" style="cursor: pointer">
                        <span aria-hidden="true">&laquo;</span>
                      </span>
                    </li>
                    <li v-for="list in paginglistPersetujuan" :key="list.nama" :value="list.value" class="page-item"
                      :class="{
                        active: list.nama == pageActivePersetujuan,
                        'd-none': list.show == false,
                      }">
                      <span class="page-link text-black" @click="
if (list.disabled != true)
                        pageActivePersetujuan = list.nama;
                        offsetPersetujuan = list.value;
                        persetujuan_nilai_index();
                        " style="cursor: pointer">{{ list.nama }}</span>
                    </li>
                    <li class="page-item">
                      <span class="page-link" @click="
if (pageActivePersetujuan != totalPagesPersetujuan) {
                        pageActivePersetujuan++;
                        offsetPersetujuan =
                          offsetPersetujuan + limitPersetujuan;
                        persetujuan_nilai_index('next');
                      }
                        " aria-label="Next" style="cursor: pointer">
                        <span aria-hidden="true">&raquo;</span>
                      </span>
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="w-25">
                <input type="text" v-model="queryPersetujuan" @change="
                  pageActivePersetujuan = 1;
                offsetPersetujuan = 0;
                persetujuan_nilai_index();
                " class="form-control" placeholder="Cari NIM, Nama Lengkap" />
              </div>
            </div>
          </div>

          <div class="border mt-1 rounded-3 font-custom-1 mb-5" style="overflow-x: auto; z-index: 0">
            <table class="table mb-0 bg-transparent table-borderless" style="">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Tanggal</th>
                  <th>NIM</th>
                  <th>Nama lengkap</th>
                  <th>Waktu Kuliah</th>

                  <th>Mata kuliah</th>
                  <th>Dosen Pengajar</th>
                  <!-- <th>Persetujuan</th> -->
                  <th>Tipe</th>
                  <th>Alasan</th>
                  <th>Status</th>
                  <th>Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(record, index) in recordsPersetujuan" :key="record.id" :ref="record.id"
                  class="border-bottom">
                  <th scope="row" class="bg-transparent">{{ index + 1 }}</th>
                  <td>{{ DateFormat(record.tanggal) }}</td>
                  <td>{{ record.nim_mahasiswa }}</td>
                  <td>{{ record.nama_lengkap }}</td>
                  <td>{{ record.waktu_kuliah }}</td>

                  <td>{{ record.mata_kuliah }}</td>
                  <td>
                    <span v-if="record.dosen_pengajar_1 != null">
                      {{ record.dosen_pengajar_1 }}
                    </span>
                    <span v-if="record.dosen_pengajar_2 != null"> & </span>
                    <span v-if="record.dosen_pengajar_2 != null">
                      {{ record.dosen_pengajar_2 }}
                    </span>
                  </td>
                  <td>
                    {{ record.tipe.toUpperCase() }}
                  </td>
                  <td>{{ record.alasan }}</td>
                  <td>{{ record.status_persetujuan }}</td>
                  <td class="bg-transparent">
                    <div class="d-flex gap-2" v-if="record.status_persetujuan == 'PERBAIKAN'">
                      <button v-if="update_akses == true" class="btn btn-danger btn-sm fs-8" @click="
                        id_komplain_nilai = record.id;
                      persetujuan_komplain_nilai = 'PERBAIKAN_KEMBALI';
                      editRecordPersetujuan(record);
                      showModal2('modal_setujui_persetujuan');
                      ">
                        <i class="bx bx-x fs-8"></i>
                      </button>
                      <button v-if="update_akses == true" class="btn btn-success btn-sm fs-8" @click="
                        id_komplain_nilai = record.id;
                      persetujuan_komplain_nilai = 'FINAL';
                      editRecordPersetujuan(record);
                      showModal2('modal_setujui_persetujuan');
                      ">
                        <i class="bx bx-check fs-8"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="hideModal2('modal_persetujuan_nilai')">
            Tutup
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal_setujui_persetujuan" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_setujui_persetujuan">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header bg-green-custom text-light">
          <h5 class="modal-title" id="exampleModalScrollableTitle">
            <span v-if="persetujuan_komplain_nilai == 'FINAL'"> SETUJU </span>
            <span v-if="persetujuan_komplain_nilai == 'PERBAIKAN_KEMBALI'">
              TOLAK
            </span>
          </h5>
          <button type="button" class="btn bg-transparent text-light border-0">
            <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_setujui_persetujuan')" />
          </button>
        </div>
        <div class="modal-body">
          <div>Apakah Anda yakin ? data tidak dapat dikembalikan</div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="hideModal2('modal_setujui_persetujuan')">
            Tidak, Batal
          </button>
          <button type="button" class="btn bg-danger text-light" @click="persetujuan_nilai_update">
            Ya
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal_pengaturan_komplain_nilai" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_pengaturan_komplain_nilai">
    <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header bg-green-custom text-light">
          <h5 class="modal-title" id="exampleModalScrollableTitle">
            Pengaturan Komplain Nilai
          </h5>
          <button type="button" class="btn bg-transparent text-light border-0">
            <img src="@/assets/icon/close.png" alt="" width="30"
              @click="hideModal2('modal_pengaturan_komplain_nilai')" />
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="tahun_ajaran" id="tahuna_ajaran"
                v-model="tahunAjaranFilterPengaturanKomplain" @change="
                  pageActive = 1;
                offset = 0;
                pengaturan_komplain_nilai_index();
                ">
                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                <option v-for="list in tahunAjaranListPengaturanKomplain" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>

            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                v-model="semesterFilterPengaturanKomplain" @change="
                  pageActive = 1;
                offset = 0;
                pengaturan_komplain_nilai_index();
                " required :disabled="tahunAjaranFilterPengaturanKomplain == ''">
                <option value="" selected>- Pilih Semester</option>
                <option v-for="list in semesterListPengaturanKomplain" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="col-8 text-end">
              <button v-if="create_akses == true" class="btn bg-green-custom fs-8 py-1 text-light font-custom-4"
                style="border-radius: 5px" @click="
                  showModal2('modal_insert_pengaturan_komplain_nilai');
                newRecordPengaturanKomplain.tipe = '';
                newRecordPengaturanKomplain.tanggal_mulai = DateNow();
                newRecordPengaturanKomplain.tanggal_selesai = DateNow();
                ">
                <i class="bx bx-list-plus me-1"></i>
                Tambah tanggal pengajuan komplain nilai
              </button>
            </div>
          </div>
          <div class="">
            <div class="d-flex align-items-start justify-content-start mt-2 gap-2">
              <div>
                <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                  v-model="limitPengaturanKomplain" @change="
                    pageActivePengaturanKomplain = 1;
                  offsetPengaturanKomplain = 0;
                  pengaturan_komplain_nilai_index();
                  " required>
                  <option v-for="list in limitList" :key="list.value" :value="list.value"
                    @change="pengaturan_komplain_nilai_index()">
                    {{ list.value }}
                  </option>
                </select>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <nav aria-label="Page navigation example">
                  <ul class="pagination" ref="pagi">
                    <li class="page-item">
                      <span class="page-link" @click="
if (pageActivePengaturanKomplain != 1) {
                        pageActivePengaturanKomplain--;
                        offsetPengaturanKomplain =
                          offsetPengaturanKomplain -
                          limitPengaturanKomplain;
                        pengaturan_komplain_nilai_index('previous');
                      }
                        " aria-label="Previous" style="cursor: pointer">
                        <span aria-hidden="true">&laquo;</span>
                      </span>
                    </li>
                    <li v-for="list in paginglistPengaturanKomplain" :key="list.nama" :value="list.value"
                      class="page-item" :class="{
                        active: list.nama == pageActivePengaturanKomplain,
                        'd-none': list.show == false,
                      }">
                      <span class="page-link text-black" @click="
if (list.disabled != true)
                        pageActivePengaturanKomplain = list.nama;
                        offsetPengaturanKomplain = list.value;
                        pengaturan_komplain_nilai_index();
                        " style="cursor: pointer">{{ list.nama }}</span>
                    </li>
                    <li class="page-item">
                      <span class="page-link" @click="
if (
                        pageActivePengaturanKomplain !=
                        totalPagesPengaturanKomplain
                      ) {
                        pageActivePengaturanKomplain++;
                        offsetPengaturanKomplain =
                          offsetPengaturanKomplain +
                          limitPengaturanKomplain;
                        pengaturan_komplain_nilai_index('next');
                      }
                        " aria-label="Next" style="cursor: pointer">
                        <span aria-hidden="true">&raquo;</span>
                      </span>
                    </li>
                  </ul>
                </nav>
              </div>
              <!-- <div class="w-25">
                                <input type="text" v-model="queryPengaturanKomplain"
                                    @change="pageActivePengaturanKomplain = 1; offsetPengaturanKomplain = 0; pengaturan_komplain_nilai_index();"
                                    class="form-control" placeholder="Cari NIM, Nama Lengkap">
                            </div> -->
            </div>
          </div>

          <div class="border mt-1 rounded-3 font-custom-1 mb-5" style="overflow-x: auto; z-index: 0">
            <table class="table mb-0 bg-transparent table-borderless" style="">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Tahun Ajaran</th>
                  <th>Semester</th>
                  <th>Tanggal Mulai</th>
                  <th>Tanggal Selesai</th>
                  <th>Tipe</th>
                  <th>Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(record, index) in recordsPengaturanKomplain" :key="record.id" :ref="record.id"
                  class="border-bottom">
                  <th scope="row" class="bg-transparent">{{ index + 1 }}</th>
                  <td>{{ record.tahun_ajaran }}</td>
                  <td>{{ record.semester }}</td>
                  <td>{{ DateFormat(record.tanggal_mulai) }}</td>
                  <td>{{ DateFormat(record.tanggal_selesai) }}</td>
                  <td class="text-uppercase">{{ record.tipe }}</td>
                  <td class="bg-transparent">
                    <div class="d-flex gap-2">
                      <button class="btn btn-warning btn-sm fs-8" @click="
                        editRecordPengaturanKomplain(record);
                      showModal2('modal_update_pengaturan_komplain_nilai');
                      ">
                        <i class="bx bxs-message-square-edit fs-8"></i>
                      </button>
                      <button class="btn btn-danger btn-sm fs-8" @click="
                        deleteRecord(record.id);
                      showModal2('modal_pengaturan_komplain_nilai_delete');
                      ">
                        <i class="bx bxs-trash-alt fs-8"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="hideModal2('modal_pengaturan_komplain_nilai')">
            Tutup
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal_pengaturan_komplain_nilai_delete" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_pengaturan_komplain_nilai_delete">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header bg-green-custom text-light">
          <h5 class="modal-title" id="exampleModalScrollableTitle">
            Hapus Data
          </h5>
          <button type="button" class="btn bg-transparent text-light border-0">
            <img src="@/assets/icon/close.png" alt="" width="30"
              @click="hideModal2('modal_pengaturan_komplain_nilai_delete')" />
          </button>
        </div>
        <div class="modal-body">
          <div>
            Apakah Anda yakin ingin menghapus data ini? data tidak dapat
            dikembalikan
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="hideModal2('modal_pengaturan_komplain_nilai_delete')">
            Tidak, tetap simpan disini
          </button>
          <button type="button" class="btn bg-danger text-light" @click="deleteDataPengaturanKomplainNilai">
            Ya,Hapus
          </button>
        </div>
      </div>
    </div>
  </div>

  <form @submit.prevent="pengaturan_komplain_nilai_update">
    <div class="modal fade" id="modal_update_pengaturan_komplain_nilai" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_update_pengaturan_komplain_nilai">
      <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header bg-green-custom text-light">
            <h5 class="modal-title" id="exampleModalScrollableTitle">
              Perbaharui tanggal pengajuan komplain nilai
            </h5>
            <button type="button" class="btn bg-transparent text-light border-0">
              <img src="@/assets/icon/close.png" alt="" width="30"
                @click="hideModal2('modal_update_pengaturan_komplain_nilai')" />
            </button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
              <select class="form-select" aria-label="Default select example" name="tahun_ajaran" id="tahuna_ajaran"
                v-model="tahunAjaranFilterPengaturanKomplain" @change="
                  pageActivePengaturanKomplain = 1;
                offsetPengaturanKomplain = 0;
                pengaturan_komplain_nilai_index();
                ">
                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                <option v-for="list in tahunAjaranListPengaturanKomplain" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label for="tahun_ajaran" class="form-label">Semester</label>
              <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                v-model="semesterFilterPengaturanKomplain" @change="
                  pageActivePengaturanKomplain = 1;
                offsetPengaturanKomplain = 0;
                pengaturan_komplain_nilai_index();
                ">
                <option value="" selected disabled>- Pilih Semester</option>
                <option v-for="list in semesterListPengaturanKomplain" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label for="tahun_ajaran" class="form-label">Tipe</label>
              <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                v-model="currentRecordPengaturanKomplain.tipe">
                <option value="" selected disabled>- Pilih tipe</option>
                <option value="uts" selected>UTS</option>
                <option value="uas" selected>UAS</option>
              </select>
            </div>
            <div class="mb-3">
              <label for="tanggal" class="form-label">Tanggal Mulai</label>
              <input type="date" class="form-control" v-model="currentRecordPengaturanKomplain.tanggal_mulai"
                required />
            </div>
            <div class="mb-3">
              <label for="tanggal" class="form-label">Tanggal Selesai</label>
              <input type="date" class="form-control" v-model="currentRecordPengaturanKomplain.tanggal_selesai"
                required />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary"
              @click="hideModal2('modal_update_pengaturan_komplain_nilai')">
              Tutup
            </button>
            <button type="submit" class="btn bg-green-custom text-light">
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <form @submit.prevent="pengaturan_komplain_nilai_insert">
    <div class="modal fade" id="modal_insert_pengaturan_komplain_nilai" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_insert_pengaturan_komplain_nilai">
      <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header bg-green-custom text-light">
            <h5 class="modal-title" id="exampleModalScrollableTitle">
              Tambah tanggal pengajuan komplain nilai
            </h5>
            <button type="button" class="btn bg-transparent text-light border-0">
              <img src="@/assets/icon/close.png" alt="" width="30"
                @click="hideModal2('modal_insert_pengaturan_komplain_nilai')" />
            </button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
              <select class="form-select" aria-label="Default select example" name="tahun_ajaran" id="tahuna_ajaran"
                v-model="tahunAjaranFilterPengaturanKomplain" @change="
                  pageActivePengaturanKomplain = 1;
                offsetPengaturanKomplain = 0;
                pengaturan_komplain_nilai_index();
                ">
                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                <option v-for="list in tahunAjaranListPengaturanKomplain" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label for="tahun_ajaran" class="form-label">Semester</label>
              <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                v-model="semesterFilterPengaturanKomplain" @change="
                  pageActivePengaturanKomplain = 1;
                offsetPengaturanKomplain = 0;
                pengaturan_komplain_nilai_index();
                ">
                <option value="" selected disabled>- Pilih Semester</option>
                <option v-for="list in semesterListPengaturanKomplain" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label for="tahun_ajaran" class="form-label">Tipe</label>
              <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                v-model="newRecordPengaturanKomplain.tipe">
                <option value="" selected disabled>- Pilih tipe</option>
                <option value="uts" selected>UTS</option>
                <option value="uas" selected>UAS</option>
              </select>
            </div>
            <div class="mb-3">
              <label for="tanggal" class="form-label">Tanggal Mulai</label>
              <input type="date" class="form-control" v-model="newRecordPengaturanKomplain.tanggal_mulai" required />
            </div>
            <div class="mb-3">
              <label for="tanggal" class="form-label">Tanggal Selesai</label>
              <input type="date" class="form-control" v-model="newRecordPengaturanKomplain.tanggal_selesai" required />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary"
              @click="hideModal2('modal_insert_pengaturan_komplain_nilai')">
              Tutup
            </button>
            <button type="submit" class="btn bg-green-custom text-light">
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="modal fade" id="modal_input_nilai_tugas_uas" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_input_nilai_tugas_uas">
    <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header bg-green-custom text-light">
          <h5 class="modal-title" id="exampleModalScrollableTitle">
            Input Tugas dan Nilai UAS
          </h5>
          <button type="button" class="btn bg-transparent text-light border-0">
            <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_input_nilai_tugas_uas')" />
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="tahun_ajaran" id="tahuna_ajaran"
                v-model="tahunAjaranFilterInputTugasUAS" @change="
                  pageActive = 1;
                offset = 0;
                inputTugasUAS_index();
                ">
                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                <option v-for="list in tahunAjaranListInputTugasUAS" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                v-model="prodiFilterInputTugasUAS" @change="
                  pageActive = 1;
                offset = 0;
                inputTugasUAS_index();
                " required>
                <option value="" selected disabled>- Pilih Prodi</option>
                <option v-for="list in prodiListInputTugasUAS" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                v-model="semesterFilterInputTugasUAS" @change="
                  pageActive = 1;
                offset = 0;
                inputTugasUAS_index();
                " required :disabled="prodiFilterInputTugasUAS == ''">
                <option value="" selected>- Pilih Semester</option>
                <option v-for="list in semesterListInputTugasUAS" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="kurikulum" id="kurikulum"
                v-model="stambukFilterInputTugasUAS" @change="
                  pageActive = 1;
                offset = 0;
                inputTugasUAS_index();
                " required :disabled="prodiFilterInputTugasUAS == ''">
                <option value="" selected disabled>- Pilih Stambuk</option>
                <option v-for="list in stambukListInputTugasUAS" :key="list.id" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                v-model="kelasFilterInputTugasUAS" @change="
                  pageActive = 1;
                offset = 0;
                mataKuliahFilterInputTugasUAS = '';
                inputTugasUAS_index();
                " required :disabled="semesterFilterInputTugasUAS == ''">
                <option value="" selected>- Pilih Kelas</option>
                <option v-for="list in kelasListInputTugasUAS" :key="list.id" :value="list.id"
                  @change="inputTugasUAS_index()">
                  {{ list.nama.toUpperCase() }}
                </option>
              </select>
            </div>

            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                v-model="mataKuliahFilterInputTugasUAS" @change="
                  pageActive = 1;
                offset = 0;
                inputTugasUAS_index();
                " required :disabled="semesterFilterInputTugasUAS == ''">
                <option value="" selected>- Pilih Mata Kuliah</option>
                <option v-for="list in mataKuliahListInputTugasUAS" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="col-2 text-center mt-2">
              <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                v-model="tampilanInputNilaiTugasUAS" @change="
                  pageActive = 1;
                offset = 0;
                " required :disabled="kelasFilterInputTugasUAS == '' || mataKuliahFilterInputTugasUAS == ''">
                <option value="" selected>- Pilih Tampilan</option>
                <option value="list_nilai" selected>List Nilai</option>
                <option value="capaian_nilai" selected>Distribusi Capaian Nilai</option>
              </select>
            </div>

          </div>
          <!-- <div class="">
            <div class="d-flex align-items-start justify-content-start mt-2 gap-2">
              <div>
                <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                  v-model="limitInputTugasUAS" @change="
                                                  pageActiveInputTugasUAS = 1;
                                                offsetInputTugasUAS = 0;
                                                perbaikan_nilai_index();
                                                " required>
                  <option v-for="list in limitList" :key="list.value" :value="list.value"
                    @change="perbaikan_nilai_index()">
                    {{ list.value }}
                  </option>
                </select>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <nav aria-label="Page navigation example">
                  <ul class="pagination" ref="pagi">
                    <li class="page-item">
                      <span class="page-link" @click="
                                                                                                                                                                                                            if (pageActiveInputTugasUAS != 1) {
                                                      pageActiveInputTugasUAS--;
                                                      offsetInputTugasUAS = offsetInputTugasUAS - limitInputTugasUAS;
                                                      perbaikan_nilai_index('previous');
                                                    }
                                                      " aria-label="Previous" style="cursor: pointer">
                        <span aria-hidden="true">&laquo;</span>
                      </span>
                    </li>
                    <li v-for="list in paginglistInputTugasUAS" :key="list.nama" :value="list.value" class="page-item"
                      :class="{
                                                        active: list.nama == pageActiveInputTugasUAS,
                                                        'd-none': list.show == false,
                                                      }">
                      <span class="page-link text-black" @click="
                                                                                                                                                                                                            if (list.disabled != true)
                                                        pageActiveInputTugasUAS = list.nama;
                                                        offsetInputTugasUAS = list.value;
                                                        perbaikan_nilai_index();
                                                        " style="cursor: pointer">{{ list.nama }}</span>
                    </li>
                    <li class="page-item">
                      <span class="page-link" @click="
                                                                                                                                                                                                            if (pageActiveInputTugasUAS != totalPagesInputTugasUAS) {
                                                          pageActiveInputTugasUAS++;
                                                          offsetInputTugasUAS = offsetInputTugasUAS + limitInputTugasUAS;
                                                          perbaikan_nilai_index('next');
                                                        }
                                                          " aria-label="Next" style="cursor: pointer">
                        <span aria-hidden="true">&raquo;</span>
                      </span>
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="w-25">
                <input type="text" v-model="queryInputTugasUAS" @change="
                                                      pageActiveInputTugasUAS = 1;
                                                    offsetInputTugasUAS = 0;
                                                    perbaikan_nilai_index();
                                                    " class="form-control" placeholder="Cari NIM, Nama Lengkap" />
              </div>
            </div>
          </div> -->

          <div class="border mt-1 rounded-3 font-custom-1 mb-5" style="overflow-x: auto; z-index: 0"
            v-if="tampilanInputNilaiTugasUAS == 'list_nilai'">
            <table class="table mb-0 bg-transparent table-borderless" style="">
              <thead>
                <tr>
                  <th>No</th>
                  <th>NIM</th>
                  <th>Nama lengkap</th>
                  <th>Mata kuliah</th>
                  <th>Kehadiran</th>
                  <th>UTS</th>
                  <th>Tugas</th>
                  <th>UAS</th>
                  <th>Quiz</th>
                  <th>Aktifitas Partisipatif</th>
                  <th>Hasil Proyek</th>

                  <th>bobot Kehadiran</th>
                  <th>bobot UTS</th>
                  <th>bobot Tugas</th>
                  <th>bobot UAS</th>
                  <th>bobot Quiz</th>
                  <th>bobot Aktitifitas Partisipatif</th>
                  <th>bobot Hasil Proyek</th>
                  <th>Nilai </th>
                  <th>Nilai Mutu</th>
                  <th>Nilai Huruf</th>
                  <th>Status Finalisasi </th>
                  <!-- <th>Aksi</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(record, index) in recordsInputTugasUAS" :key="record.id" :ref="record.id"
                  class="border-bottom ">
                  <th scope="row" class="bg-transparent">{{ index + 1
                    }}</th>
                  <td :class="{ 'text-danger': record.kehadiran < 75 }">{{ record.nim_mahasiswa }}</td>
                  <td :class="{ 'text-danger': record.kehadiran < 75 }">{{ record.nama_lengkap }}</td>
                  <td :class="{ 'text-danger': record.kehadiran < 75 }">{{ record.nama }}</td>
                  <td class="fw-bolder" :class="{ 'text-danger': record.kehadiran < 75 }">{{ record.kehadiran }}%</td>
                  <td :class="{ 'text-danger': record.kehadiran < 75 }">{{ record.uts }}</td>
                  <td v-if="record.status_finalisasi_dosen == 'FINAL'">{{ record.tugas
                    }}</td>
                  <td v-if="record.status_finalisasi_dosen == 'PENDING'"><input type="text" class="form-control"
                      v-model="record.tugas" @change="validateNilai(record.tugas, index, 'tugas')"
                      style="width:70px!important" /></td>
                  <td v-if="record.status_finalisasi_dosen == 'FINAL'">{{ record.uas }}
                  </td>
                  <td v-if="record.status_finalisasi_dosen == 'PENDING'"><input type="text" class="form-control"
                      v-model="record.uas" @change="validateNilai(record.uas, index, 'uas')"
                      style="width:70px!important" /></td>

                  <td v-if="record.status_finalisasi_dosen == 'FINAL'">{{ record.quiz
                    }}</td>
                  <td v-if="record.status_finalisasi_dosen == 'PENDING'"><input type="text" class="form-control"
                      v-model="record.quiz" @change="validateNilai(record.quiz, index, 'quiz')"
                      style="width:70px!important" /></td>
                  <td v-if="record.status_finalisasi_dosen == 'FINAL'">{{
                    record.aktifitas_partisipatif }}</td>
                  <td v-if="record.status_finalisasi_dosen == 'PENDING'"><input type="text" class="form-control"
                      v-model="record.aktifitas_partisipatif"
                      @change="validateNilai(record.aktifitas_partisipatif, index, 'aktifitas_partisipatif')"
                      style="width:70px!important" /></td>

                  <td v-if="record.status_finalisasi_dosen == 'FINAL'">{{
                    record.hasil_proyek }}</td>
                  <td v-if="record.status_finalisasi_dosen == 'PENDING'"><input type="text" class="form-control"
                      v-model="record.hasil_proyek" @change="validateNilai(record.hasil_proyek, index, 'hasil_proyek')"
                      style="width:70px!important" /></td>

                  <td :class="{ 'text-danger': record.kehadiran < 75 }">{{ record.bobot_kehadiran }}</td>
                  <td :class="{ 'text-danger': record.kehadiran < 75 }">{{ record.bobot_uts }}</td>
                  <td :class="{ 'text-danger': record.kehadiran < 75 }">{{ record.bobot_tugas }}</td>
                  <td :class="{ 'text-danger': record.kehadiran < 75 }">{{ record.bobot_uas }}</td>
                  <td :class="{ 'text-danger': record.kehadiran < 75 }">{{ record.bobot_quiz }}</td>
                  <td :class="{ 'text-danger': record.kehadiran < 75 }">{{ record.bobot_aktifitas_partisipatif }}</td>
                  <td :class="{ 'text-danger': record.kehadiran < 75 }">{{ record.bobot_hasil_proyek }}</td>
                  <td :class="{ 'text-danger': record.kehadiran < 75 }">{{
                    cekIsNan((
                      (record.kehadiran * (record.bobot_kehadiran) / 100)
                      + (record.uts * (record.bobot_uts) / 100) +
                      (record.tugas * (record.bobot_tugas) / 100) +
                      (record.uas * (record.bobot_uas) / 100) +
                      + ((record.quiz === '-') ? 0 : record.quiz * ((record.quiz === '-') ? 0 : record.bobot_quiz) / 100)
                      + ((record.hasil_proyek === '-') ? 0 : record.hasil_proyek * ((record.hasil_proyek === '-') ? 0 :
                        record.bobot_hasil_proyek) / 100)
                      + ((record.aktifitas_partisipatif === '-') ? 0 : record.aktifitas_partisipatif *
                        ((record.aktifitas_partisipatif === '-') ? 0 : record.bobot_aktifitas_partisipatif) /
                        100)
                    ).toFixed(2), record.tugas, record.uts, record.uas, record.quiz, record.bobot_quiz,
                      record.hasil_proyek,
                      record.bobot_hasil_proyek, record.aktifitas_partisipatif, record.bobot_aktifitas_partisipatif)
                  }}</td>
                  <td :class="{ 'text-danger': record.kehadiran < 75 }">{{ cekNilaiMutu((
                    (record.kehadiran * (record.bobot_kehadiran) / 100)
                    + (record.uts * (record.bobot_uts) / 100)
                    + (record.tugas * (record.bobot_tugas) / 100)
                    + (record.uas * (record.bobot_uas) / 100)
                    + ((record.quiz === '-') ? 0 : record.quiz * ((record.quiz === '-') ? 0 : record.bobot_quiz) / 100)
                    + ((record.hasil_proyek === '-') ? 0 : record.hasil_proyek * ((record.hasil_proyek === '-') ? 0 :
                      record.bobot_hasil_proyek) / 100)
                    + ((record.aktifitas_partisipatif === '-') ? 0 : record.aktifitas_partisipatif *
                      ((record.aktifitas_partisipatif === '-') ? 0 : record.bobot_aktifitas_partisipatif) /
                      100)
                  ).toFixed(2),
                    record.tugas, record.uts, record.uas, record.quiz, record.bobot_quiz, record.hasil_proyek,
                    record.bobot_hasil_proyek, record.aktifitas_partisipatif, record.bobot_aktifitas_partisipatif)
                    }}
                  </td>
                  <td :class="{ 'text-danger': record.kehadiran < 75 }">{{ cekNilaiHuruf((
                    (record.kehadiran * (record.bobot_kehadiran) / 100)
                    + (record.uts * (record.bobot_uts) / 100)
                    + (record.tugas * (record.bobot_tugas) / 100)
                    + (record.uas * (record.bobot_uas) / 100)
                    + ((record.quiz === '-') ? 0 : record.quiz * ((record.quiz === '-') ? 0 : record.bobot_quiz) / 100)
                    + ((record.hasil_proyek === '-') ? 0 : record.hasil_proyek * ((record.hasil_proyek === '-') ? 0 :
                      record.bobot_hasil_proyek) / 100)
                    + ((record.aktifitas_partisipatif === '-') ? 0 : record.aktifitas_partisipatif *
                      ((record.aktifitas_partisipatif === '-') ? 0 : record.bobot_aktifitas_partisipatif) /
                      100)
                  ).toFixed(2),
                    record.tugas, record.uts, record.uas, record.quiz, record.bobot_quiz, record.hasil_proyek,
                    record.bobot_hasil_proyek, record.aktifitas_partisipatif, record.bobot_aktifitas_partisipatif)
                    }}
                  </td>
                  <td :class="{ 'text-danger': record.kehadiran < 75 }">{{ record.status_finalisasi_dosen }}</td>
                  <!-- <td class="bg-transparent">
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
          <div class="border mt-1 rounded-3 font-custom-1 mb-5" style="overflow-x: auto; z-index: 0"
            v-if="tampilanInputNilaiTugasUAS == 'capaian_nilai'">
            <table class="table mb-0 bg-transparent table-borderless" style="">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Nilai Huruf</th>
                  <th>Jumlah</th>
                  <!-- <th>Aksi</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(record, index) in listCapaianNilai" :key="record.id" :ref="record.id" class="border-bottom">
                  <th scope="row" class="bg-transparent">{{ index + 1 }}</th>
                  <td>{{ record.grade }}</td>
                  <td>{{ record.jumlah_siswa }}</td>
                  <!-- <td class="bg-transparent">
                  </td> -->
                </tr>
              </tbody>
            </table>

          </div>
          <div class="w-100 d-flex justify-content-center " style="height:300px!important">
            <div class="w-50">
              <Bar id="my-chart-id" :options="chartOptions" :data="chartData"
                v-if="tampilanInputNilaiTugasUAS == 'capaian_nilai'" />
            </div>
          </div>


        </div>
        <div class="modal-footer">
          <!-- <div v-if="recordsInputTugasUAS.length > 0">
            {{ recordsInputTugasUAS[0].status_finalisasi_dosen }}
            ||
            {{ recordsInputTugasUAS[0].status_finalisasi_kaprodi }}
            ||
            {{ recordsInputTugasUAS[0].status_finalisasi_akademik }}
          </div> -->
          <button type="button" class="btn btn-secondary" @click="hideModal2('modal_input_nilai_tugas_uas')">
            Tutup
          </button>
          <button class="btn btn-success "
            v-if="recordsInputTugasUAS.length > 0 && recordsInputTugasUAS[0].status_finalisasi_dosen == 'PENDING' && recordsInputTugasUAS[0].status_finalisasi_kaprodi == 'PENDING' && recordsInputTugasUAS[0].status_finalisasi_akademik == 'PENDING' && (tipe == 'admin' || (tipe == 'dosen' && create_akses == true))"
            @click="cekNilaiUASTUGAS('simpan', true)">
            Simpan
          </button>
          <button class="btn btn-success " v-if="(recordsInputTugasUAS.length > 0 && recordsInputTugasUAS[0].status_finalisasi_dosen == 'PENDING' && recordsInputTugasUAS[0].status_finalisasi_kaprodi == 'PENDING' && recordsInputTugasUAS[0].status_finalisasi_akademik == 'PENDING')
            && (tipe == 'admin' || (tipe == 'dosen' && update_akses == true))
          " @click="finalisasi = 'YA'; jenis_finalisasi = 'dosen'; cekNilaiUASTUGAS('finalisasi_dosen', true);">
            Finalisasi Nilai Dosen
          </button>
          <button class="btn btn-danger " v-if="(recordsInputTugasUAS.length > 0 && recordsInputTugasUAS[0].status_finalisasi_dosen == 'FINAL' && recordsInputTugasUAS[0].status_finalisasi_kaprodi == 'PENDING' && recordsInputTugasUAS[0].status_finalisasi_akademik == 'PENDING')
            && (tipe == 'admin' || (tipe == 'pegawai' && update_akses == true))
          " @click=" finalisasi = 'TIDAK'; jenis_finalisasi = 'dosen'; finalisasiNilaiUASTugas()">
            Batalkan Finalisasi Nilai Dosen
          </button>
          <button class="btn btn-success "
            v-if="(recordsInputTugasUAS.length > 0 && recordsInputTugasUAS[0].status_finalisasi_dosen == 'FINAL' && recordsInputTugasUAS[0].status_finalisasi_kaprodi == 'PENDING' && recordsInputTugasUAS[0].status_finalisasi_akademik == 'PENDING')
              && (tipe == 'admin' || (tipe == 'pegawai' && update_akses == true) || (tipe == 'pegawai' && update_akses == true) || (tipe == 'dosen' && update_akses == true && id_prodi_kaprodi_akses == prodiFilterInputTugasUAS && status_kaprodi == 'true'))"
            @click="finalisasi = 'YA'; jenis_finalisasi = 'kaprodi'; cekNilaiUASTUGAS('finalisasi', true);">
            Finalisasi Kaprodi
          </button>
          <button class="btn btn-danger " v-if="(recordsInputTugasUAS.length > 0 && recordsInputTugasUAS[0].status_finalisasi_dosen == 'FINAL' && recordsInputTugasUAS[0].status_finalisasi_kaprodi == 'FINAL' && recordsInputTugasUAS[0].status_finalisasi_akademik == 'PENDING')
            && (tipe == 'admin' || (tipe == 'pegawai' && update_akses == true) || (tipe == 'pegawai' && (update_akses == true && id_prodi_kaprodi_akses == prodiFilterInputTugasUAS)))
          " @click=" finalisasi = 'TIDAK'; jenis_finalisasi = 'kaprodi'; finalisasiNilaiUASTugas()">
            Batalkan Finalisasi Kaprodi
          </button>
          <button class="btn btn-success "
            v-if="(recordsInputTugasUAS.length > 0 && recordsInputTugasUAS[0].status_finalisasi_dosen == 'FINAL' && recordsInputTugasUAS[0].status_finalisasi_kaprodi == 'FINAL' && recordsInputTugasUAS[0].status_finalisasi_akademik == 'PENDING')
              && (tipe == 'admin' || (tipe == 'pegawai' && update_akses == true) || (tipe == 'pegawai' && update_akses == true))"
            @click="finalisasi = 'YA'; jenis_finalisasi = 'pegawai'; cekNilaiUASTUGAS('finalisasi', true);">
            Finalisasi Akademik
          </button>
          <button class="btn btn-danger " v-if="(recordsInputTugasUAS.length > 0 && recordsInputTugasUAS[0].status_finalisasi_dosen == 'FINAL' && recordsInputTugasUAS[0].status_finalisasi_kaprodi == 'FINAL' && recordsInputTugasUAS[0].status_finalisasi_akademik == 'FINAL')
            && (tipe == 'admin' || (tipe == 'pegawai' && update_akses == true) || (tipe == 'pegawai' && update_akses == true))
          " @click=" finalisasi = 'TIDAK'; jenis_finalisasi = 'pegawai'; finalisasiNilaiUASTugas()">
            Batalkan Finalisasi Kaprodi
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal_input_nilai" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_input_nilai">
    <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header bg-green-custom text-light">
          <h5 class="modal-title" id="exampleModalScrollableTitle">
            Input Nilai
          </h5>
          <button type="button" class="btn bg-transparent text-light border-0">
            <img src="@/assets/icon/close.png" alt="" width="30"
              @click="hideModal2('modal_input_nilai'); show_modal_input_nilai = false;" />
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="tahun_ajaran" id="tahuna_ajaran"
                v-model="tahunAjaranFilter" @change="
                  pageActive = 1;
                offset = 0;
                index();
                ">
                <option value="" selected disabled>
                  - Pilih Tahun Ajaran
                </option>
                <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                v-model="prodiFilter" @change="
                  pageActive = 1;
                offset = 0;
                index();
                " required>
                <option value="" selected disabled>- Pilih Prodi</option>
                <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                v-model="semesterFilter" @change="
                  pageActive = 1;
                offset = 0;
                index();
                " required :disabled="prodiFilter == ''">
                <option value="" selected>- Pilih Semester</option>
                <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="kelas" id="kelas"
                v-model="stambukFilter" @change="
                  pageActive = 1;
                offset = 0;
                index();
                " required :disabled="prodiFilter == ''">
                <option value="" selected>- Pilih Stambuk</option>
                <option v-for="list in stambukList" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="kelas" id="kelas"
                v-model="kelasFilter" @change="
                  pageActive = 1;
                offset = 0;
                index();
                " required :disabled="stambukFilter == ''">
                <option value="" selected>- Pilih Kelas</option>
                <option v-for="list in kelasList" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="col-2 text-center">
              <select class="form-select" aria-label="Default select example" name="mata_kuliah" id="mata_kuliah"
                v-model="mataKuliahFilter" @change="
                  pageActive = 1;
                offset = 0;
                index();
                " required :disabled="kelasFilter == ''">
                <option value="" selected>- Pilih Mata Kuliah</option>
                <option v-for="list in mataKuliahList" :key="list.nama" :value="list.id">
                  {{ list.nama }}
                </option>
              </select>
            </div>
            <div class="col-2 text-center mt-3 mb-3">
              <select class="form-select" aria-label="Default select example" name="mata_kuliah" id="mata_kuliah"
                v-model="tipe_import" required @change="index();">
                <option value="" selected disabled>- Pilih Nilai</option>
                <option value="uts" selected>UTS</option>
                <!-- <option value="uas_tugas" selected>UAS & TUGAS</option> -->
                <option value="susulan_uts" selected>SUSULAN (UTS)</option>
                <option value="remedial" selected>REMEDIAL</option>
                <option value="susulan_uas" selected>SUSULAN (UAS)</option>
                <option value="semester_pendek" selected>SEMESTER PENDEK</option>
              </select>
            </div>
          </div>
          <div class="border mt-1 rounded-3 font-custom-1 mb-5" style="overflow-x: auto; z-index: 0">
            <table class="table mb-0 bg-transparent table-borderless" style="">
              <thead>
                <tr>
                  <th>No</th>
                  <th>NIM</th>
                  <th>Nama lengkap</th>
                  <th>Nilai </th>
                  <th>Tipe</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(record, index) in recordsNilai" :key="record.id" :ref="record.id" class="border-bottom ">
                  <th scope="row" class="bg-transparent">{{ index + 1
                    }}</th>
                  <td>{{ record.nim_mahasiswa }}</td>
                  <td>{{ record.nama_lengkap }}</td>
                  <td><input type="text" class="form-control" v-model="record.nilai" style="width:70px!important"
                      :disabled="status_finalisasi_cek.status_finalisasi_dosen == 'FINAL'" />
                  </td>
                  <td>{{ record.tipe }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <!-- {{ status_finalisasi_cek.status_finalisasi_dosen }} ||
          {{ status_finalisasi_cek.status_finalisasi_kaprodi }} ||
          {{ status_finalisasi_cek.status_finalisasi_akademik }} ||
          {{ tipe }} ||
          {{ status_kaprodi.status_kaprodi }} -->
          <button type="button" class="btn btn-secondary"
            @click="hideModal2('modal_input_nilai'); show_modal_input_nilai = false;">
            Tutup
          </button>
          <button type="button" v-if="(update_akses == true && status_finalisasi_cek.status_finalisasi_dosen == 'PENDING' && status_finalisasi_cek.status_finalisasi_kaprodi == 'PENDING' && status_finalisasi_cek.status_finalisasi_akademik == 'PENDING' && ((tipe == 'dosen' && (status_kaprodi.status_mengajar == 'true')) || tipe == 'admin')) && recordsNilai.length > 0
          " class="btn bg-green-custom text-light" @click="importNilai('true')">
            Finalisasi Dosen
          </button>
          <button type="button" v-if="(update_akses == true && status_finalisasi_cek.status_finalisasi_dosen == 'FINAL' && status_finalisasi_cek.status_finalisasi_kaprodi == 'PENDING' && status_finalisasi_cek.status_finalisasi_akademik == 'PENDING' && ((tipe == 'pegawai' || tipe == 'admin'))) && recordsNilai.length > 0
          " class="btn bg-danger text-light"
            @click="finalisasi = 'TIDAK'; jenis_finalisasi = 'dosen'; finalisasiNilai()">
            Batalkan Finalisasi Dosen
          </button>
          <button type="button" v-if="(update_akses == true && status_finalisasi_cek.status_finalisasi_dosen == 'FINAL' && status_finalisasi_cek.status_finalisasi_kaprodi == 'PENDING' && status_finalisasi_cek.status_finalisasi_akademik == 'PENDING' && ((tipe == 'dosen' && status_kaprodi.status_kaprodi == 'true' && prodiFilter == status_kaprodi.id_prodi) || tipe == 'admin')) && recordsNilai.length > 0
          " class="btn bg-green-custom text-light"
            @click="finalisasi = 'YA'; jenis_finalisasi = 'kaprodi'; finalisasiNilai()">
            Finalisasi Kaprodi
          </button>
          <button type="button" v-if="(update_akses == true && status_finalisasi_cek.status_finalisasi_dosen == 'FINAL' && status_finalisasi_cek.status_finalisasi_kaprodi == 'FINAL' && status_finalisasi_cek.status_finalisasi_akademik == 'PENDING' && (tipe == 'pegawai' || tipe == 'admin')) && recordsNilai.length > 0
          " class="btn bg-danger text-light"
            @click="finalisasi = 'TIDAK'; jenis_finalisasi = 'kaprodi'; finalisasiNilai()">
            Batalkan Finalisasi Kaprodi
          </button>
          <button type="button" v-if="(update_akses == true && status_finalisasi_cek.status_finalisasi_dosen == 'FINAL' && status_finalisasi_cek.status_finalisasi_kaprodi == 'FINAL' && status_finalisasi_cek.status_finalisasi_akademik == 'PENDING' && (tipe == 'pegawai' || tipe == 'admin')) && recordsNilai.length > 0
          " class="btn bg-green-custom text-light"
            @click="finalisasi = 'YA'; jenis_finalisasi = 'pegawai'; finalisasiNilai()">
            Finalisasi Akademik
          </button>
          <button type="button" v-if="(update_akses == true && status_finalisasi_cek.status_finalisasi_dosen == 'FINAL' && status_finalisasi_cek.status_finalisasi_kaprodi == 'FINAL' && status_finalisasi_cek.status_finalisasi_akademik == 'FINAL' && (tipe == 'pegawai' || tipe == 'admin')) && recordsNilai.length > 0
          " class="btn bg-danger text-light"
            @click="finalisasi = 'TIDAK'; jenis_finalisasi = 'pegawai'; finalisasiNilai()">
            Batalkan Finalisasi Kaprodi
          </button>
          <button type="button" class="btn btn-success" @click="importNilai()"
            v-if="status_finalisasi_cek.status_finalisasi_dosen == 'PENDING' || status_finalisasi_cek.status_finalisasi_dosen == ''">
            Simpan
          </button>
        </div>
      </div>
    </div>
  </div>


  <button class="btn btn-success d-none" id="btn_laporan_import" @click="showModal2('laporan_import')"
    ref="btn_laporan_import" hidden>
    <i class="bx bxs-file-import"></i> Laporan Import Nilai
  </button>

  <div>
    <div class="modal fade" id="laporan_import" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
      aria-labelledby="staticBackdropLabel" aria-hidden="true" ref="laporan_import">
      <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Status Import
              <span class="text-capitalize"> Nilai</span>
            </h5>
            <button type="button" class="btn-close" @click="hideModal2('laporan_import')" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <table class="table fs-6">
                <thead style="
                    position: sticky !important;
                    top: -20px !important;
                    background-color: white;
                  ">
                  <tr>
                    <th scope="col" style="width: 5%">No</th>
                    <th scope="col">status</th>
                    <th scope="col">Data</th>
                  </tr>
                </thead>
                <tbody id="data_laporan">
                  <tr v-for="(   laporanRecords, index   ) in laporanRecords   " :key="laporanRecords.id">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <i v-if="laporanRecords.status === 'SUKSES'" class="bx bxs-check-square text-success"></i>
                      <i v-if="laporanRecords.status === 'GAGAL'" class="bx bxs-x-square text-danger"></i>
                    </td>
                    <td>{{ laporanRecords.text }}</td>
                  </tr>
                </tbody>
              </table>
              <!-- <div class="modal-footer ">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="btn_tutup_laporan_kehadiran_2" onclick="refresh_page()">Tutup</button>
                                </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button class="btn btn-success d-none" id="btn_laporan_invalid" @click="showModal2('laporan_invalid')"
    ref="btn_laporan_invalid" hidden>
    <i class="bx bxs-file-invalid"></i> Laporan Import Invalid
  </button>

  <div>
    <div class="modal fade" id="laporan_invalid" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
      aria-labelledby="staticBackdropLabel" aria-hidden="true" ref="laporan_invalid">
      <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header  bg-danger text-white">
            <h5 class="modal-title" id="exampleModalLabel">
              Terdapat Nilai yang kosong atau tidak valid
              <!-- <span class="text-capitalize"> Nilai</span> -->
            </h5>
            <button type="button" class="btn-close" @click="hideModal2('laporan_invalid')" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <table class="table fs-6">
                <thead style="
                    position: sticky !important;
                    top: -20px !important;
                    background-color: white;
                  ">
                  <tr>
                    <th scope="col" style="width: 5%">No</th>
                    <th scope="col">status</th>
                    <th scope="col">Data</th>
                  </tr>
                </thead>
                <tbody id="data_laporan">
                  <tr v-for="(   laporanRecords, index   ) in laporanRecords   " :key="laporanRecords.id">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <i v-if="laporanRecords.status === 'SUKSES'" class="bx bxs-check-square text-success"></i>
                      <i v-if="laporanRecords.status === 'GAGAL'" class="bx bxs-x-square text-danger"></i>
                    </td>
                    <td class="text-danger">{{ laporanRecords.text }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="modal-footer ">
                <button type="button" class="btn btn-secondary" id="btn_tutup_laporan_invalid"
                  @click="hideModal2('laporan_invalid'); cekNilaiUASTUGAS('', false)">LANJUT SIMPAN</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.pagination {
  --bs-pagination-color: black !important;
}

.active>.page-link,
.page-link.active {
  background-color: #0d9044 !important;
  border-color: #0d9044 !important;
  color: white !important;
}

.padding-body-show-navbar {
  padding-left: 190px !important;
}

.padding-body-hide-navbar {
  padding-left: 82px !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounceIn {
  0% {
    transform: scale(0.5);
    opacity: 0.1;
  }

  50% {
    transform: scale(1.05);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.delete-animation {
  animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(-20px);
  }
}

@keyframes bounceOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1);
    opacity: 0.4;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.animation-hide-modal {
  animation: bounceOut 0.3s ease-in;
}

.animation-show-modal {
  animation: bounceIn 0.3s ease-out;
}

::-webkit-scrollbar {
  width: 0.3rem;
  border-radius: 0.5rem;
  background-color: rgba(63, 63, 63, 0.656);
  height: 0.4rem;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.473);
  border-radius: 0.5rem;
  width: 0.3rem;
}

::-webkit-scrollbar-thumb:hover {
  width: 0.3rem;
  background-color: rgba(255, 255, 255, 0.797);
}

.table tbody tr:last-child {
  border: 0px white !important;
}
</style>

<script>
import {
  AlertPopup,
  hariList,
  base_url,
  DateFormat,
  CloseLoading,
  ShowLoading,
  menu_akses_cek,
  ErrorConnectionTimeOut,
  AlertBottom,
  CharAndNumberOnly,
  DateNow,
} from "@/assets/javascript/function";
import SideNavbar from "@/components/SideNavbar.vue";
import Cookies from "js-cookie";
import axios from "axios";
import XLSX from "xlsx/dist/xlsx.full.min.js";

// bar
import { Bar } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);


export default {
  name: "BarChart",

  data() {
    return {
      //chart 
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Distribusi Capaian Nilai ",
            backgroundColor: "rgba(0, 40, 145, 0.2)",
            borderColor: "rgba(0, 40, 145, 1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(0, 40, 145, 0.4)",
            hoverBorderColor: "rgba(0, 40, 145, 1)",
            data: [],
          },

        ],
      },
      chartOptions: {
        responsive: true,
        // maintainAspectRatio: false,
        // Set the width to 100 pixels
        maintainAspectRatio: false,
        scales: {
          x: {
            beginAtZero: true,
          },
          y: {
            beginAtZero: true,
          },
        },

      },
      key2: 0,


      //data
      url: "nilai",
      url2: "penilaian",
      api: "",

      //pages
      limit: 100,
      offset: 0,
      previousLastId: "",
      NextLastId: "",
      previousLastIdOld: "",
      NextLastIdOld: "",
      arrayLastIdOld: [],
      arrayPreviousIdOld: [],
      numberPage: 1,
      totalPages: 0,
      currentPageActive: 1,
      pageActive: 1,

      prodiList: [],
      waktuKuliahList: [],
      dosenWaliList: [],
      semesterList: [],
      stambukList: [],
      tahunAjaranList: [],
      mataKuliahList: [],
      kelasList: [],
      limitList: [
        {
          value: 5,
        },
        {
          value: 10,
        },
        {
          value: 25,
        },
        {
          value: 50,
        },
        {
          value: 100,
        },
      ],

      prodiFilter: "",
      semesterFilter: "",
      tahunAjaranFilter: "",
      kelasFilter: "",
      stambukFilter: "",
      mataKuliahFilter: "",
      query: "",

      tipe_import: "",

      jenis_finalisasi: "",

      records: [],
      newRecord: {
        nama: "",
        prodi: "",
        waktu_kuliah: "",
        dosen_wali: "",
        stambuk: "",
        keterangan: "",
        kelas: "",
      },
      currentRecordNilai: [],
      currentRecord: [],
      mataKuliahNilaiRecords: [],
      mahasiswaRecords: [],
      attendanceRecords: [],
      penggantiList: [],
      paginglist: [],
      isLoad: 0,
      laporanRecords: [],

      //modal berita acara
      mata_kuliah_dipilih: "",
      id_jadwal_dipilih: "",
      id_dosen_dipilih: "",
      id_profiles_dipilih: "",
      batas_page_awal: 0,
      batas_page_akhir: 10,

      //akses
      create_akses: false,
      update_akses: false,
      delete_akses: false,

      //tampilan
      isShowNavbar: true,
      temporaryId: "",
      temporaryStatusDosen: "",

      finalisasi: "",
      setujui: "",
      tipe: "",

      // komplain nilai
      numberPageKomplain: 1,
      totalPagesKomplain: 0,
      currentPageActiveKomplain: 1,
      pageActiveKomplain: 1,
      recordsKomplain: [],
      currentRecordKomplain: {
        nim_mahasiswa: "",
        nama_lengkap: "",
        mata_kuliah: "",
        tipe: "",
        dosen_pengajar: "",
        nomor_invoice: "",
        status_Komplain: "",
        tanggal: "",
        alasan: "",
      },
      limitKomplain: 5,
      offsetKomplain: 0,
      batas_page_awalKomplain: 0,
      batas_page_akhirKomplain: 10,

      //
      prodiListKomplain: [],
      waktuKuliahListKomplain: [],
      dosenWaliListKomplain: [],
      semesterListKomplain: [],
      tahunAjaranListKomplain: [],
      hariJadwalListKomplain: [],
      mataKuliahListKomplain: [],
      kurikulumListKomplain: [],

      //
      prodiFilterKomplain: "",
      semesterFilterKomplain: "",
      tahunAjaranFilterKomplain: "",
      kurikulumFilterKomplain: "",
      tipeFilterKomplain: "",
      MataKuliahFilterKomplain: "",
      queryKomplain: "",
      status_terima_Komplain: "",
      tipeListKomplain: [],

      //modal setuju
      persetujuan_komplain_nilai: "",
      id_komplain_nilai: "",

      paginglistKomplain: [],
      isLoadKomplain: 0,

      // perbaikan nilai
      numberPagePerbaikan: 1,
      totalPagesPerbaikan: 0,
      currentPageActivePerbaikan: 1,
      pageActivePerbaikan: 1,
      recordsPerbaikan: [],
      currentRecordPerbaikan: {
        nim_mahasiswa: "",
        nama_lengkap: "",
        mata_kuliah: "",
        tipe: "",
        dosen_pengajar: "",
        nomor_invoice: "",
        status_Perbaikan: "",
        tanggal: "",
        alasan: "",
      },
      limitPerbaikan: 5,
      offsetPerbaikan: 0,
      batas_page_awalPerbaikan: 0,
      batas_page_akhirPerbaikan: 10,
      //
      prodiListPerbaikan: [],
      waktuKuliahListPerbaikan: [],
      dosenWaliListPerbaikan: [],
      semesterListPerbaikan: [],
      tahunAjaranListPerbaikan: [],
      hariJadwalListPerbaikan: [],
      mataKuliahListPerbaikan: [],
      kurikulumListPerbaikan: [],

      //
      prodiFilterPerbaikan: "",
      semesterFilterPerbaikan: "",
      tahunAjaranFilterPerbaikan: "",
      kurikulumFilterPerbaikan: "",
      tipeFilterPerbaikan: "",
      MataKuliahFilterPerbaikan: "",
      queryPerbaikan: "",
      status_terima_Perbaikan: "",
      tipeListPerbaikan: [],

      paginglistPerbaikan: [],
      isLoadPerbaikan: 0,

      // persetujuan nilai
      numberPagePersetujuan: 1,
      totalPagesPersetujuan: 0,
      currentPageActivePersetujuan: 1,
      pageActivePersetujuan: 1,
      recordsPersetujuan: [],
      currentRecordPersetujuan: {
        nim_mahasiswa: "",
        nama_lengkap: "",
        mata_kuliah: "",
        tipe: "",
        dosen_pengajar: "",
        nomor_invoice: "",
        status_Persetujuan: "",
        tanggal: "",
        alasan: "",
      },
      limitPersetujuan: 5,
      offsetPersetujuan: 0,
      batas_page_awalPersetujuan: 0,
      batas_page_akhirPersetujuan: 10,

      //
      prodiListPersetujuan: [],
      waktuKuliahListPersetujuan: [],
      dosenWaliListPersetujuan: [],
      semesterListPersetujuan: [],
      tahunAjaranListPersetujuan: [],
      hariJadwalListPersetujuan: [],
      mataKuliahListPersetujuan: [],
      kurikulumListPersetujuan: [],

      //
      prodiFilterPersetujuan: "",
      semesterFilterPersetujuan: "",
      tahunAjaranFilterPersetujuan: "",
      kurikulumFilterPersetujuan: "",
      tipeFilterPersetujuan: "",
      MataKuliahFilterPersetujuan: "",
      queryPersetujuan: "",
      status_terima_Persetujuan: "",
      tipeListPersetujuan: [],

      paginglistPersetujuan: [],
      isLoadPersetujuan: 0,

      //Pengaturankomplain nilai portal akademik
      numberPagePengaturanKomplain: 1,
      totalPagesPengaturanKomplain: 0,
      currentPageActivePengaturanKomplain: 1,
      pageActivePengaturanKomplain: 1,
      recordsPengaturanKomplain: [],
      currentRecordPengaturanKomplain: {},
      newRecordPengaturanKomplain: { tipe: "" },
      limitPengaturanKomplain: 5,
      offsetPengaturanKomplain: 0,
      batas_page_awalPengaturanKomplain: 0,
      batas_page_akhirPengaturanKomplain: 10,

      prodiListPengaturanKomplain: [],
      waktuKuliahListPengaturanKomplain: [],
      dosenWaliListPengaturanKomplain: [],
      semesterListPengaturanKomplain: [],
      tahunAjaranListPengaturanKomplain: [],
      hariJadwalListPengaturanKomplain: [],
      mataKuliahListPengaturanKomplain: [],
      kurikulumListPengaturanKomplain: [],

      prodiFilterPengaturanKomplain: "",
      semesterFilterPengaturanKomplain: "",
      tahunAjaranFilterPengaturanKomplain: "",
      kurikulumFilterPengaturanKomplain: "",
      tipeFilterPengaturanKomplain: "",
      MataKuliahFilterPengaturanKomplain: "",
      queryPengaturanKomplain: "",
      status_terima_PengaturanKomplain: "",
      tipeListPengaturanKomplain: [],

      paginglistPengaturanKomplain: [],
      isLoadPengaturanKomplain: 0,

      //jumlah pending
      jumlah_pending_komplain: 0,
      jumlah_setuju_komplain: 0,
      jumlah_perbaikan_komplain: 0,


      //InputTugasUAS
      numberPageInputTugasUAS: 1,
      totalPagesInputTugasUAS: 0,
      currentPageActiveInputTugasUAS: 1,
      pageActiveInputTugasUAS: 1,
      recordsInputTugasUAS: [],
      currentRecordInputTugasUAS: {
        nim_mahasiswa: "",
        nama_lengkap: "",
        mata_kuliah: "",
        tipe: "",
        dosen_pengajar: "",
        nomor_invoice: "",
        status_InputTugasUAS: "",
        tanggal: "",
        alasan: "",
      },
      limitInputTugasUAS: 5,
      offsetInputTugasUAS: 0,
      batas_page_awalInputTugasUAS: 0,
      batas_page_akhirInputTugasUAS: 10,

      //
      prodiListInputTugasUAS: [],
      waktuKuliahListInputTugasUAS: [],
      dosenWaliListInputTugasUAS: [],
      semesterListInputTugasUAS: [],
      tahunAjaranListInputTugasUAS: [],
      hariJadwalListInputTugasUAS: [],
      stambukListInputTugasUAS: [],
      mataKuliahListInputTugasUAS: [],
      kurikulumListInputTugasUAS: [],
      kelasListInputTugasUAS: [],

      //
      prodiFilterInputTugasUAS: "",
      semesterFilterInputTugasUAS: "",
      tahunAjaranFilterInputTugasUAS: "",
      kurikulumFilterInputTugasUAS: "",
      tipeFilterInputTugasUAS: "",
      stambukFilterInputTugasUAS: "",
      kelasFilterInputTugasUAS: "",
      mataKuliahFilterInputTugasUAS: "",
      queryInputTugasUAS: "",
      status_terima_InputTugasUAS: "",
      tipeListInputTugasUAS: [],

      paginglistInputTugasUAS: [],
      isLoadInputTugasUAS: 0,

      tampilanInputNilaiTugasUAS: "list_nilai",
      listCapaianNilai: [],

      status_finalisasi_cek: {
        status_finalisasi_dosen: '',
        status_finalisasi_akademik: '',
        status_finalisasi_kaprodi: '',
      },
      status_cek_nilai: "",

      //cek kaprodi akess
      id_prodi_kaprodi_akses: "",


      //input nilai 
      recordsNilai: "",
      status_kaprodi: "true",

      show_modal_input_nilai: false,
    };
  },
  components: {
    SideNavbar, Bar,
  },
  computed: {
    returnValueStatusDosen() {
      return this.currentRecordBeritaAcara.status_dosen ? "TIDAK" : "HADIR";
    },
  },

  methods: {
    validateNilai(input, index, tipe) {

      // Check if the input is '-'
      if (input === '-') {
        return true; // If it's '-', return true without validation
      }

      // Convert input to a number
      const value = parseFloat(input);

      // Check if the value is above 100
      if (value > 100) {
        AlertPopup("error", "", "nilai tidak valid ", 1500, false);
        this.recordsInputTugasUAS[index][tipe] = "";

      } else {
        return true; // If it's not above 100, return false
      }

    },
    cekIsNan(nilai, tugas, uts, uas, quiz, bobot_quiz, hasil_proyek, bobot_hasil_proyek, aktifitas_partisipatif, bobot_aktifitas_partisipatif) {
      if (nilai == 'NaN') {
        return '0';
      }

      if (tugas === '-' || uts === '-' || uas === '-' || (quiz === '-' && bobot_quiz > 0) || (hasil_proyek === '-' && bobot_hasil_proyek > 0) || (aktifitas_partisipatif === '-' && bobot_aktifitas_partisipatif > 0)) {
        return '0';
      }
      return nilai;

    },
    cekNilaiMutu(nilai_mutu, tugas, uts, uas, quiz, bobot_quiz, hasil_proyek, bobot_hasil_proyek, aktifitas_partisipatif, bobot_aktifitas_partisipatif) {
      if (tugas === '-' || uts === '-' || uas === '-' || (quiz === '-' && bobot_quiz > 0) || (hasil_proyek === '-' && bobot_hasil_proyek > 0) || (aktifitas_partisipatif === '-' && bobot_aktifitas_partisipatif > 0)) {
        return 0.00;
      } else {
        if (nilai_mutu >= 85 && nilai_mutu <= 100) {
          return 4.00;
        } else if (nilai_mutu >= 80 && nilai_mutu < 85) {
          return 3.75;
        } else if (nilai_mutu >= 75 && nilai_mutu < 80) {
          return 3.50;
        } else if (nilai_mutu >= 70 && nilai_mutu < 75) {
          return 3.00;
        } else if (nilai_mutu >= 65 && nilai_mutu < 70) {
          return 2.75;
        } else if (nilai_mutu > 60 && nilai_mutu <= 65) {
          return 2.50;
        } else if (nilai_mutu > 50 && nilai_mutu <= 60) {
          return 2.00;
        } else if (nilai_mutu > 40 && nilai_mutu <= 50) {
          return 1.00;
        } else if (nilai_mutu >= 0 && nilai_mutu < 40) {
          return 0.00;
        } else {
          return 0.00;
        }

      }
    },
    cekNilaiHuruf(nilai_mutu, tugas, uts, uas, quiz, bobot_quiz, hasil_proyek, bobot_hasil_proyek, aktifitas_partisipatif, bobot_aktifitas_partisipatif) {
      if (tugas === '-' || uts === '-' || uas === '-' || (quiz === '-' && bobot_quiz > 0) || (hasil_proyek === '-' && bobot_hasil_proyek > 0) || (aktifitas_partisipatif === '-' && bobot_aktifitas_partisipatif > 0)) {
        return 'E';
      } else {
        if (nilai_mutu >= 85 && nilai_mutu <= 100) {
          return 'A';
        } else if (nilai_mutu >= 80 && nilai_mutu < 85) {
          return 'A-';
        } else if (nilai_mutu >= 75 && nilai_mutu < 80) {
          return 'B+';
        } else if (nilai_mutu >= 70 && nilai_mutu < 75) {
          return 'B';
        } else if (nilai_mutu >= 65 && nilai_mutu < 70) {
          return 'B-';
        } else if (nilai_mutu >= 60 && nilai_mutu < 65) {
          return 'C+';
        } else if (nilai_mutu >= 50 && nilai_mutu < 60) {
          return 'C';
        } else if (nilai_mutu >= 40 && nilai_mutu < 50) {
          return 'D';
        } else if (nilai_mutu >= 0 && nilai_mutu < 40) {
          return 'E';
        } else {
          return 'E';
        }
      }
    },
    pengaturan_komplain_nilai_insert() {
      ShowLoading();
      const formData = new FormData();

      delete this.currentRecordPengaturanKomplain.tahun_ajaran;
      delete this.currentRecordPengaturanKomplain.semester;

      formData.append("tahun_ajaran", this.tahunAjaranFilterPengaturanKomplain);
      formData.append("semester", this.semesterFilterPengaturanKomplain);
      Object.entries(this.newRecordPengaturanKomplain).forEach(
        ([key, value]) => {
          formData.append(key, value);
        }
      );
      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "pengaturan_komplain_nilai_insert";
      } else {
        this.api = base_url + "pengaturan_komplain_nilai_insert";
      }
      // Add a new record to the API
      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            this.newRecordPengaturanKomplain = {};
            this.recordsPengaturanKomplain.unshift(response.data.data);
            CloseLoading();
            AlertBottom(response.data.message);
            this.hideModal2("modal_insert_pengaturan_komplain_nilai");
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    pengaturan_komplain_nilai_update() {
      ShowLoading();

      let formData = new FormData();
      delete this.currentRecordPengaturanKomplain.semester;
      delete this.currentRecordPengaturanKomplain.tahun_ajaran;
      formData.append("tahun_ajaran", this.tahunAjaranFilterPengaturanKomplain);
      formData.append("semester", this.semesterFilterPengaturanKomplain);

      Object.entries(this.currentRecordPengaturanKomplain).forEach(
        ([key, value]) => {
          formData.append(key, value);
        }
      );

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "pengaturan_komplain_nilai_update";
      } else {
        this.api = base_url + "pengaturan_komplain_nilai_update";
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            const index = this.recordsPengaturanKomplain.findIndex(
              (record) => record.id == response.data.data.id
            );
            this.currentRecordPengaturanKomplain = {};
            // this.$set(this.records, index, response.data.data);
            this.recordsPengaturanKomplain.splice(index, 1, response.data.data);

            AlertBottom(response.data.message);
            this.hideModal2("modal_update_pengaturan_komplain_nilai");
            CloseLoading();
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    pengaturan_komplain_nilai_index(status) {
      this.paginglistPengaturanKomplain = [];
      ShowLoading();
      this.recordsPengaturanKomplain = [];

      let formData = new FormData();
      formData.append("limit", this.limitPengaturanKomplain);
      formData.append("offset", this.offsetPengaturanKomplain);
      formData.append("tahun_ajaran", this.tahunAjaranFilterPengaturanKomplain);
      formData.append("prodi", this.prodiFilterPengaturanKomplain);
      formData.append("semester", this.semesterFilterPengaturanKomplain);
      formData.append("query", this.queryPengaturanKomplain);
      formData.append("kurikulum", this.kurikulumFilterPengaturanKomplain);
      formData.append("tipe", this.tipeFilterPengaturanKomplain);
      formData.append("mata_kuliah", this.MataKuliahFilterPengaturanKomplain);

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "pengaturan_komplain_nilai_index";
      } else {
        this.api = base_url + "pengaturan_komplain_nilai_index";
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            this.semesterListPengaturanKomplain = response.data.semester;
            this.tahunAjaranListPengaturanKomplain = response.data.tahun_ajaran;
            if (response.data.data != undefined) {
              this.recordsPengaturanKomplain = response.data.data[1];
              this.totalPagesPengaturanKomplain =
                response.data.data[0][0].number_page;

              let limit = this.limitPengaturanKomplain;
              this.pageActivePengaturanKomplain;

              let page_list_array = [];
              let page_list;
              for (let i = 1; i <= response.data.data[0][0].number_page; i++) {
                if (i == 1) {
                  limit = 0;
                } else {
                  limit = limit + this.limitPengaturanKomplain;
                }

                if (
                  i <= this.batas_page_akhirPengaturanKomplain &&
                  i > this.batas_page_awalPengaturanKomplain
                ) {
                  page_list = {
                    nama: i,
                    value: limit,
                    key: "page_number_" + i,
                    show: true,
                    disabled: false,
                  };
                } else {
                  page_list = {
                    nama: i,
                    value: limit,
                    key: "page_number_" + i,
                    show: false,
                    disabled: false,
                  };
                }
                page_list_array.push(page_list);
              }

              if (page_list_array && page_list_array.length > 0) {
                let index_last_true;
                let index_first_true;
                for (let i = 0; i < page_list_array.length; i++) {
                  if (page_list_array[i].show == true) {
                    index_last_true = i;
                  }
                }
                for (let i = 0; i < page_list_array.length; i++) {
                  if (page_list_array[i].show == true) {
                    index_first_true = i;
                    break;
                  }
                }

                if (
                  page_list_array[this.batas_page_akhirPengaturanKomplain] !=
                  undefined &&
                  page_list_array[index_last_true].key ==
                  page_list_array[this.pageActivePengaturanKomplain - 1].key
                ) {
                  page_list_array[
                    this.batas_page_awalPengaturanKomplain
                  ].show = false;
                  page_list_array[
                    this.batas_page_akhirPengaturanKomplain
                  ].show = true;

                  this.batas_page_awalPengaturanKomplain++;
                  this.batas_page_akhirPengaturanKomplain++;
                } else if (this.pageActive != 1) {
                  if (
                    page_list_array[index_first_true].key ==
                    page_list_array[this.pageActivePengaturanKomplain - 1].key
                  ) {
                    this.batas_page_awalPengaturanKomplain--;
                    this.batas_page_akhirPengaturanKomplain--;

                    page_list_array[
                      this.batas_page_awalPengaturanKomplain
                    ].show = true;
                    page_list_array[
                      this.batas_page_akhirPengaturanKomplain
                    ].show = false;
                  }
                }

                if (status == "next") {
                  if (
                    page_list_array[index_last_true].key ==
                    page_list_array[this.pageActivePengaturanKomplain - 1]
                      .key &&
                    page_list_array[this.batas_page_akhirPengaturanKomplain] ==
                    undefined
                  ) {
                    //
                  } else {
                    //
                  }
                } else if (
                  status == "previous" &&
                  this.pageActivePengaturanKomplain != 1
                ) {
                  // this.pageActive--; this.offset = page_list_array[this.pageActive - 1].value;
                }

                for (let i = 0; i < page_list_array.length; i++) {
                  this.paginglistPengaturanKomplain.push(page_list_array[i]);
                }

                let pemisahPages = {
                  nama: "...",
                  value: "...",
                  key: "page_number_...",
                  show: true,
                  disabled: true,
                };

                let totalPages = {
                  nama: this.totalPagesPengaturanKomplain,
                  value: "0",
                  key: "page_number_total_pages",
                  show: true,
                  disabled: true,
                };

                if (
                  page_list_array[index_last_true + 1] &&
                  (page_list_array[index_last_true + 1].show != true ||
                    page_list_array[index_last_true + 1].nama !=
                    totalPages.nama)
                ) {
                  this.paginglistPengaturanKomplain.push(pemisahPages);
                  this.paginglistPengaturanKomplain.push(totalPages);
                }
              }
            }

            CloseLoading();
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    persetujuan_nilai_update() {
      ShowLoading();

      let formData = new FormData();
      formData.append("id", this.id_komplain_nilai);
      formData.append("status_persetujuan", this.persetujuan_komplain_nilai);
      formData.append("uts", this.currentRecordPersetujuan.uts);
      formData.append("uas", this.currentRecordPersetujuan.uas);

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "komplain_nilai_update";
      } else {
        this.api = base_url + "komplain_nilai_update";
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            const index = this.recordsPersetujuan.findIndex(
              (record) => record.id == response.data.data.id
            );

            this.recordsPersetujuan.splice(index, 1);
            AlertBottom(response.data.message);
            this.hideModal2("modal_setujui_persetujuan");
            CloseLoading();
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    persetujuan_nilai_index(status) {
      this.paginglistPersetujuan = [];
      ShowLoading();
      this.recordsPersetujuan = [];

      let formData = new FormData();
      formData.append("limit", this.limitPersetujuan);
      formData.append("offset", this.offsetPersetujuan);
      formData.append("tahun_ajaran", this.tahunAjaranFilterPersetujuan);
      formData.append("prodi", this.prodiFilterPersetujuan);
      formData.append("semester", this.semesterFilterPersetujuan);
      formData.append("query", this.queryPersetujuan);
      formData.append("kurikulum", this.kurikulumFilterPersetujuan);
      formData.append("tipe", this.tipeFilterPersetujuan);
      formData.append("mata_kuliah", this.MataKuliahFilterPersetujuan);

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "komplain_nilai_perbaikan_index";
      } else {
        this.api = base_url + "komplain_nilai_perbaikan_index";
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            this.prodiListPersetujuan = response.data.prodi;
            this.semesterListPersetujuan = response.data.semester;
            this.tahunAjaranListPersetujuan = response.data.tahun_ajaran;
            this.mataKuliahListPersetujuan = response.data.mata_kuliah;
            this.kurikulumListPersetujuan = response.data.kurikulum;
            this.tipeListPersetujuan = response.data.tipe;

            if (response.data.data != undefined) {
              this.recordsPersetujuan = response.data.data[1];
              this.totalPagesPersetujuan = response.data.data[0][0].number_page;

              let limit = this.limitPersetujuan;
              this.pageActivePersetujuan;

              let page_list_array = [];
              let page_list;
              for (let i = 1; i <= response.data.data[0][0].number_page; i++) {
                if (i == 1) {
                  limit = 0;
                } else {
                  limit = limit + this.limitPersetujuan;
                }

                if (
                  i <= this.batas_page_akhirPersetujuan &&
                  i > this.batas_page_awalPersetujuan
                ) {
                  page_list = {
                    nama: i,
                    value: limit,
                    key: "page_number_" + i,
                    show: true,
                    disabled: false,
                  };
                } else {
                  page_list = {
                    nama: i,
                    value: limit,
                    key: "page_number_" + i,
                    show: false,
                    disabled: false,
                  };
                }
                page_list_array.push(page_list);
              }

              if (page_list_array && page_list_array.length > 0) {
                let index_last_true;
                let index_first_true;
                for (let i = 0; i < page_list_array.length; i++) {
                  if (page_list_array[i].show == true) {
                    index_last_true = i;
                  }
                }
                for (let i = 0; i < page_list_array.length; i++) {
                  if (page_list_array[i].show == true) {
                    index_first_true = i;
                    break;
                  }
                }

                if (
                  page_list_array[this.batas_page_akhirPersetujuan] !=
                  undefined &&
                  page_list_array[index_last_true].key ==
                  page_list_array[this.pageActivePersetujuan - 1].key
                ) {
                  page_list_array[this.batas_page_awalPersetujuan].show = false;
                  page_list_array[this.batas_page_akhirPersetujuan].show = true;

                  this.batas_page_awalPersetujuan++;
                  this.batas_page_akhirPersetujuan++;
                } else if (this.pageActive != 1) {
                  if (
                    page_list_array[index_first_true].key ==
                    page_list_array[this.pageActivePersetujuan - 1].key
                  ) {
                    this.batas_page_awalPersetujuan--;
                    this.batas_page_akhirPersetujuan--;

                    page_list_array[
                      this.batas_page_awalPersetujuan
                    ].show = true;
                    page_list_array[
                      this.batas_page_akhirPersetujuan
                    ].show = false;
                  }
                }

                if (status == "next") {
                  if (
                    page_list_array[index_last_true].key ==
                    page_list_array[this.pageActivePersetujuan - 1].key &&
                    page_list_array[this.batas_page_akhirPersetujuan] ==
                    undefined
                  ) {
                    //
                  } else {
                    //
                  }
                } else if (
                  status == "previous" &&
                  this.pageActivePersetujuan != 1
                ) {
                  // this.pageActive--; this.offset = page_list_array[this.pageActive - 1].value;
                }

                for (let i = 0; i < page_list_array.length; i++) {
                  this.paginglistPersetujuan.push(page_list_array[i]);
                }

                let pemisahPages = {
                  nama: "...",
                  value: "...",
                  key: "page_number_...",
                  show: true,
                  disabled: true,
                };

                let totalPages = {
                  nama: this.totalPagesPersetujuan,
                  value: "0",
                  key: "page_number_total_pages",
                  show: true,
                  disabled: true,
                };

                if (
                  page_list_array[index_last_true + 1] &&
                  (page_list_array[index_last_true + 1].show != true ||
                    page_list_array[index_last_true + 1].nama !=
                    totalPages.nama)
                ) {
                  this.paginglistPersetujuan.push(pemisahPages);
                  this.paginglistPersetujuan.push(totalPages);
                }
              }
            }

            CloseLoading();
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    perbaikan_nilai_update() {
      ShowLoading();

      let formData = new FormData();
      formData.append("id", this.currentRecordPerbaikan.id);
      formData.append("status_persetujuan", "PERBAIKAN");
      if (this.currentRecordPerbaikan.uts == "") {
        formData.append("uts", "-1");
      } else {
        formData.append("uts", this.currentRecordPerbaikan.uts);
      }

      if (this.currentRecordPerbaikan.uas == "") {
        formData.append("uas", "-1");
      } else {
        formData.append("uas", this.currentRecordPerbaikan.uas);
      }

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "komplain_nilai_update";
      } else {
        this.api = base_url + "komplain_nilai_update";
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            const index = this.recordsPerbaikan.findIndex(
              (record) => record.id == response.data.data.id
            );
            this.recordsPerbaikan.splice(index, 1);
            AlertBottom(response.data.message);
            this.hideModal2("modal_perbaikan_nilai_update");
            CloseLoading();
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    perbaikan_nilai_index(status) {
      this.paginglistPerbaikan = [];
      ShowLoading();
      this.recordsPerbaikan = [];

      let formData = new FormData();
      formData.append("limit", this.limitPerbaikan);
      formData.append("offset", this.offsetPerbaikan);
      formData.append("tahun_ajaran", this.tahunAjaranFilterPerbaikan);
      formData.append("prodi", this.prodiFilterPerbaikan);
      formData.append("semester", this.semesterFilterPerbaikan);
      formData.append("query", this.queryPerbaikan);
      formData.append("kurikulum", this.kurikulumFilterPerbaikan);
      formData.append("tipe", this.tipeFilterPerbaikan);
      formData.append("mata_kuliah", this.MataKuliahFilterPerbaikan);

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "komplain_nilai_setujui_index";
      } else {
        this.api = base_url + "komplain_nilai_setujui_index";
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            this.prodiListPerbaikan = response.data.prodi;
            this.semesterListPerbaikan = response.data.semester;
            this.tahunAjaranListPerbaikan = response.data.tahun_ajaran;
            this.mataKuliahListPerbaikan = response.data.mata_kuliah;
            this.kurikulumListPerbaikan = response.data.kurikulum;
            this.tipeListPerbaikan = response.data.tipe;

            if (response.data.data != undefined) {
              this.recordsPerbaikan = response.data.data[1];
              this.totalPagesPerbaikan = response.data.data[0][0].number_page;

              let limit = this.limitPerbaikan;
              this.pageActivePerbaikan;

              let page_list_array = [];
              let page_list;
              for (let i = 1; i <= response.data.data[0][0].number_page; i++) {
                if (i == 1) {
                  limit = 0;
                } else {
                  limit = limit + this.limitPerbaikan;
                }

                if (
                  i <= this.batas_page_akhirPerbaikan &&
                  i > this.batas_page_awalPerbaikan
                ) {
                  page_list = {
                    nama: i,
                    value: limit,
                    key: "page_number_" + i,
                    show: true,
                    disabled: false,
                  };
                } else {
                  page_list = {
                    nama: i,
                    value: limit,
                    key: "page_number_" + i,
                    show: false,
                    disabled: false,
                  };
                }
                page_list_array.push(page_list);
              }

              if (page_list_array && page_list_array.length > 0) {
                let index_last_true;
                let index_first_true;
                for (let i = 0; i < page_list_array.length; i++) {
                  if (page_list_array[i].show == true) {
                    index_last_true = i;
                  }
                }
                for (let i = 0; i < page_list_array.length; i++) {
                  if (page_list_array[i].show == true) {
                    index_first_true = i;
                    break;
                  }
                }

                if (
                  page_list_array[this.batas_page_akhirPerbaikan] !=
                  undefined &&
                  page_list_array[index_last_true].key ==
                  page_list_array[this.pageActivePerbaikan - 1].key
                ) {
                  page_list_array[this.batas_page_awalPerbaikan].show = false;
                  page_list_array[this.batas_page_akhirPerbaikan].show = true;

                  this.batas_page_awalPerbaikan++;
                  this.batas_page_akhirPerbaikan++;
                } else if (this.pageActive != 1) {
                  if (
                    page_list_array[index_first_true].key ==
                    page_list_array[this.pageActivePerbaikan - 1].key
                  ) {
                    this.batas_page_awalPerbaikan--;
                    this.batas_page_akhirPerbaikan--;

                    page_list_array[this.batas_page_awalPerbaikan].show = true;
                    page_list_array[
                      this.batas_page_akhirPerbaikan
                    ].show = false;
                  }
                }

                if (status == "next") {
                  if (
                    page_list_array[index_last_true].key ==
                    page_list_array[this.pageActivePerbaikan - 1].key &&
                    page_list_array[this.batas_page_akhirPerbaikan] == undefined
                  ) {
                    //
                  } else {
                    //
                  }
                } else if (
                  status == "previous" &&
                  this.pageActivePerbaikan != 1
                ) {
                  // this.pageActive--; this.offset = page_list_array[this.pageActive - 1].value;
                }

                for (let i = 0; i < page_list_array.length; i++) {
                  this.paginglistPerbaikan.push(page_list_array[i]);
                }

                let pemisahPages = {
                  nama: "...",
                  value: "...",
                  key: "page_number_...",
                  show: true,
                  disabled: true,
                };

                let totalPages = {
                  nama: this.totalPagesPerbaikan,
                  value: "0",
                  key: "page_number_total_pages",
                  show: true,
                  disabled: true,
                };

                if (
                  page_list_array[index_last_true + 1] &&
                  (page_list_array[index_last_true + 1].show != true ||
                    page_list_array[index_last_true + 1].nama !=
                    totalPages.nama)
                ) {
                  this.paginglistPerbaikan.push(pemisahPages);
                  this.paginglistPerbaikan.push(totalPages);
                }
              }
            }

            CloseLoading();
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    komplain_nilai_update() {
      ShowLoading();

      let formData = new FormData();
      formData.append("id", this.id_komplain_nilai);
      formData.append("status_persetujuan", this.persetujuan_komplain_nilai);
      formData.append("uts", this.currentRecordKomplain.uts);
      formData.append("uas", this.currentRecordKomplain.uas);

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "komplain_nilai_update";
      } else {
        this.api = base_url + "komplain_nilai_update";
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            const index = this.recordsKomplain.findIndex(
              (record) => record.id == response.data.data.id
            );

            this.recordsKomplain.splice(index, 1, response.data.data);
            AlertBottom(response.data.message);
            this.hideModal2("modal_setujui");
            CloseLoading();
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    komplain_nilai_index(status) {

      this.paginglistKomplain = [];
      ShowLoading();
      this.recordsKomplain = [];

      let formData = new FormData();
      formData.append("limit", this.limitKomplain);
      formData.append("offset", this.offsetKomplain);
      formData.append("tahun_ajaran", this.tahunAjaranFilterKomplain);
      formData.append("prodi", this.prodiFilterKomplain);
      formData.append("semester", this.semesterFilterKomplain);
      formData.append("query", this.queryKomplain);
      formData.append("kurikulum", this.kurikulumFilterKomplain);
      formData.append("tipe", this.tipeFilterKomplain);
      formData.append("mata_kuliah", this.MataKuliahFilterKomplain);

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "komplain_nilai_index";
      } else {
        this.api = base_url + "komplain_nilai_index";
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          this.prodiListKomplain = response.data.prodi;
          this.semesterListKomplain = response.data.semester;
          this.tahunAjaranListKomplain = response.data.tahun_ajaran;
          this.mataKuliahListKomplain = response.data.mata_kuliah;
          this.kurikulumListKomplain = response.data.kurikulum;
          this.tipeListKomplain = response.data.tipe;

          if (response.data.status == true && response.data.data != undefined && Array.isArray(response.data.data[1])) {

            if (response.data.data != undefined) {
              this.recordsKomplain = response.data.data[1];
              this.totalPagesKomplain = response.data.data[0][0].number_page;

              let limit = this.limitKomplain;
              this.pageActiveKomplain;

              let page_list_array = [];
              let page_list;
              for (let i = 1; i <= response.data.data[0][0].number_page; i++) {
                if (i == 1) {
                  limit = 0;
                } else {
                  limit = limit + this.limitKomplain;
                }

                if (
                  i <= this.batas_page_akhirKomplain &&
                  i > this.batas_page_awalKomplain
                ) {
                  page_list = {
                    nama: i,
                    value: limit,
                    key: "page_number_" + i,
                    show: true,
                    disabled: false,
                  };
                } else {
                  page_list = {
                    nama: i,
                    value: limit,
                    key: "page_number_" + i,
                    show: false,
                    disabled: false,
                  };
                }
                page_list_array.push(page_list);
              }

              if (page_list_array && page_list_array.length > 0) {
                let index_last_true;
                let index_first_true;
                for (let i = 0; i < page_list_array.length; i++) {
                  if (page_list_array[i].show == true) {
                    index_last_true = i;
                  }
                }
                for (let i = 0; i < page_list_array.length; i++) {
                  if (page_list_array[i].show == true) {
                    index_first_true = i;
                    break;
                  }
                }

                if (
                  page_list_array[this.batas_page_akhirKomplain] != undefined &&
                  page_list_array[index_last_true].key ==
                  page_list_array[this.pageActiveKomplain - 1].key
                ) {
                  page_list_array[this.batas_page_awalKomplain].show = false;
                  page_list_array[this.batas_page_akhirKomplain].show = true;

                  this.batas_page_awalKomplain++;
                  this.batas_page_akhirKomplain++;
                } else if (this.pageActive != 1) {
                  if (
                    page_list_array[index_first_true].key ==
                    page_list_array[this.pageActiveKomplain - 1].key
                  ) {
                    this.batas_page_awalKomplain--;
                    this.batas_page_akhirKomplain--;

                    page_list_array[this.batas_page_awalKomplain].show = true;
                    page_list_array[this.batas_page_akhirKomplain].show = false;
                  }
                }

                if (status == "next") {
                  if (
                    page_list_array[index_last_true].key ==
                    page_list_array[this.pageActiveKomplain - 1].key &&
                    page_list_array[this.batas_page_akhirKomplain] == undefined
                  ) {
                    //
                  } else {
                    //
                  }
                } else if (
                  status == "previous" &&
                  this.pageActiveKomplain != 1
                ) {
                  // this.pageActive--; this.offset = page_list_array[this.pageActive - 1].value;
                }

                for (let i = 0; i < page_list_array.length; i++) {
                  this.paginglistKomplain.push(page_list_array[i]);
                }

                let pemisahPages = {
                  nama: "...",
                  value: "...",
                  key: "page_number_...",
                  show: true,
                  disabled: true,
                };

                let totalPages = {
                  nama: this.totalPagesKomplain,
                  value: "0",
                  key: "page_number_total_pages",
                  show: true,
                  disabled: true,
                };

                if (
                  page_list_array[index_last_true + 1] &&
                  (page_list_array[index_last_true + 1].show != true ||
                    page_list_array[index_last_true + 1].nama !=
                    totalPages.nama)
                ) {
                  this.paginglistKomplain.push(pemisahPages);
                  this.paginglistKomplain.push(totalPages);
                }
              }
            }
            CloseLoading();
          }
          if (response.data.data != undefined && response.data.data[0][0].status == 0) {
            AlertPopup("error", "", response.data.data[0][0].message, 1500, false);
          } else {
            CloseLoading();
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    inputTugasUAS_index(status) {
      this.paginglistInputTugasUAS = [];
      ShowLoading();
      this.recordsInputTugasUAS = [];

      let formData = new FormData();

      formData.append("limit", this.limitInputTugasUAS);
      formData.append("offset", this.offsetInputTugasUAS);
      formData.append("tahun_ajaran", this.tahunAjaranFilterInputTugasUAS);
      formData.append("prodi", this.prodiFilterInputTugasUAS);
      formData.append("semester", this.semesterFilterInputTugasUAS);
      formData.append("kelas", this.kelasFilterInputTugasUAS);
      formData.append("query", this.queryInputTugasUAS);
      formData.append("stambuk", this.stambukFilterInputTugasUAS);
      formData.append("mata_kuliah", this.mataKuliahFilterInputTugasUAS);


      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "nilai_index_input_nilai_tugas_uas";
      } else {
        this.api = base_url + "nilai_index_input_nilai_tugas_uas";
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            this.prodiListInputTugasUAS = response.data.prodi;
            this.semesterListInputTugasUAS = response.data.semester;
            this.tahunAjaranListInputTugasUAS = response.data.tahun_ajaran;
            this.kelasListInputTugasUAS = response.data.kelas;

            this.mataKuliahListInputTugasUAS = response.data.mata_kuliah;
            this.stambukListInputTugasUAS = response.data.stambuk;
            this.listCapaianNilai = response.data.jumlah_grade;
            this.id_prodi_kaprodi_akses = response.data.prodi_kaprodi_akses;
            this.status_kaprodi = response.data.status_kaprodi;
            // console.log(this.id_prodi_kaprodi_akses);


            this.chartData.labels = [];
            this.chartData.datasets[0].data = [];
            response.data.jumlah_grade.forEach((value) => {
              this.chartData.labels.push(value.grade);
              this.chartData.datasets[0].data.push(value.jumlah_siswa);
            });
            this.key2 += 1;

            if (response.data.data != undefined && this.kelasFilterInputTugasUAS != "") {
              this.recordsInputTugasUAS = response.data.data[1];
              this.totalPagesInputTugasUAS = response.data.data[0][0].number_page;

              let limit = this.limitInputTugasUAS;
              this.pageActiveInputTugasUAS;
              let page_list_array = [];
              let page_list;
              for (let i = 1; i <= response.data.data[0][0].number_page; i++) {
                if (i == 1) {
                  limit = 0;
                } else {
                  limit = limit + this.limitInputTugasUAS;
                }

                if (i <= this.batas_page_akhirInputTugasUAS && i > this.batas_page_awalInputTugasUAS) {
                  page_list = {
                    nama: i,
                    value: limit,
                    key: "page_number_" + i,
                    show: true,
                    disabled: false,
                  };
                } else {
                  page_list = {
                    nama: i,
                    value: limit,
                    key: "page_number_" + i,
                    show: false,
                    disabled: false,
                  };
                }
                page_list_array.push(page_list);
              }

              if (page_list_array && page_list_array.length > 0) {
                let index_last_true;
                let index_first_true;
                for (let i = 0; i < page_list_array.length; i++) {
                  if (page_list_array[i].show == true) {
                    index_last_true = i;
                  }
                }
                for (let i = 0; i < page_list_array.length; i++) {
                  if (page_list_array[i].show == true) {
                    index_first_true = i;
                    break;
                  }
                }

                if (
                  page_list_array[this.batas_page_akhirInputTugasUAS] != undefined &&
                  page_list_array[index_last_true].key ==
                  page_list_array[this.pageActiveInputTugasUAS - 1].key
                ) {
                  page_list_array[this.batas_page_awalInputTugasUAS].show = false;
                  page_list_array[this.batas_page_akhirInputTugasUAS].show = true;

                  this.batas_page_awalInputTugasUAS++;
                  this.batas_page_akhirInputTugasUAS++;
                } else if (this.pageActiveInputTugasUAS != 1) {
                  if (
                    page_list_array[index_first_true].key ==
                    page_list_array[this.pageActiveInputTugasUAS - 1].key
                  ) {
                    this.batas_page_awalInputTugasUAS--;
                    this.batas_page_akhirInputTugasUAS--;

                    page_list_array[this.batas_page_awalInputTugasUAS].show = true;
                    page_list_array[this.batas_page_akhirInputTugasUAS].show = false;
                  }
                }

                if (status == "next") {
                  if (
                    page_list_array[index_last_true].key ==
                    page_list_array[this.pageActiveInputTugasUAS - 1].key &&
                    page_list_array[this.batas_page_akhirInputTugasUAS] == undefined
                  ) {
                    //
                  } else {
                    //
                  }
                } else if (status == "previous" && this.pageActiveInputTugasUAS != 1) {
                  // this.pageActive--; this.offset = page_list_array[this.pageActive - 1].value;
                }

                for (let i = 0; i < page_list_array.length; i++) {
                  this.paginglistInputTugasUAS.push(page_list_array[i]);
                }

                let pemisahPages = {
                  nama: "...",
                  value: "...",
                  key: "page_number_...",
                  show: true,
                  disabled: true,
                };

                let totalPages = {
                  nama: this.totalPagesInputTugasUAS,
                  value: "0",
                  key: "page_number_total_pages",
                  show: true,
                  disabled: true,
                };
                // console.log(page_list_array[index_last_true]);
                if (
                  page_list_array[index_last_true + 1] &&
                  (page_list_array[index_last_true + 1].show != true ||
                    page_list_array[index_last_true + 1].nama !=
                    totalPages.nama)
                ) {
                  this.paginglistInputTugasUAS.push(pemisahPages);
                  this.paginglistInputTugasUAS.push(totalPages);
                }
              }
            }

            CloseLoading();
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    capitalizeWords(str) {
      return str
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    addNumberToObjects(objArray) {
      for (let i = 0; i < objArray.length; i++) {
        const newObj = { No: i + 1, ...objArray[i] };
        objArray[i] = newObj;
      }
      return objArray;
    },
    CharAndNumberOnly,
    DateFormat,
    DateNow,
    async index(status) {
      this.paginglist = [];
      ShowLoading();
      this.records = [];

      let formData = new FormData();

      formData.append("limit", this.limit);
      formData.append("offset", this.offset);
      formData.append("tahun_ajaran", this.tahunAjaranFilter);
      formData.append("prodi", this.prodiFilter);
      formData.append("semester", this.semesterFilter);
      formData.append("kelas", this.kelasFilter);
      formData.append("query", this.query);
      formData.append("stambuk", this.stambukFilter);
      formData.append("tipe_import", this.tipe_import);
      formData.append("id_mata_kuliah", this.mataKuliahFilter);

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + this.url + "_index";
      } else {
        this.api = base_url + this.url + "_index";
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            this.prodiList = response.data.prodi;
            this.semesterList = response.data.semester;
            this.tahunAjaranList = response.data.tahun_ajaran;
            this.kelasList = response.data.kelas;
            this.mataKuliahList = response.data.mata_kuliah;
            this.stambukList = response.data.stambuk;
            this.jumlah_pending_komplain =
              response.data.jumlah_pending_komplain.jumlah_pending_komplain;
            this.jumlah_perbaikan_komplain =
              response.data.jumlah_perbaikan_komplain.jumlah_perbaikan_komplain;
            this.jumlah_setuju_komplain =
              response.data.jumlah_setuju_komplain.jumlah_setuju_komplain;
            if (response.data.status_finalisasi == undefined) {
              this.status_finalisasi_cek = {
                status_finalisasi_dosen: '',
                status_finalisasi_akademik: '',
                status_finalisasi_kaprodi: '',
              };

            } else {
              this.status_finalisasi_cek = response.data.status_finalisasi;
            }
            this.status_kaprodi = response.data.status_kaprodi;
            console.log(this.status_kaprodi);

            if (this.show_modal_input_nilai == true && this.tipe_import != '') {
              this.indexInputNilai();
            }

            if (response.data.data != undefined && this.kelasFilter != "") {
              this.records = response.data.data[1];
              this.totalPages = response.data.data[0][0].number_page;

              let limit = this.limit;
              this.pageActive;
              let page_list_array = [];
              let page_list;
              for (let i = 1; i <= response.data.data[0][0].number_page; i++) {
                if (i == 1) {
                  limit = 0;
                } else {
                  limit = limit + this.limit;
                }

                if (i <= this.batas_page_akhir && i > this.batas_page_awal) {
                  page_list = {
                    nama: i,
                    value: limit,
                    key: "page_number_" + i,
                    show: true,
                    disabled: false,
                  };
                } else {
                  page_list = {
                    nama: i,
                    value: limit,
                    key: "page_number_" + i,
                    show: false,
                    disabled: false,
                  };
                }
                page_list_array.push(page_list);
              }

              if (page_list_array && page_list_array.length > 0) {
                let index_last_true;
                let index_first_true;
                for (let i = 0; i < page_list_array.length; i++) {
                  if (page_list_array[i].show == true) {
                    index_last_true = i;
                  }
                }
                for (let i = 0; i < page_list_array.length; i++) {
                  if (page_list_array[i].show == true) {
                    index_first_true = i;
                    break;
                  }
                }

                if (
                  page_list_array[this.batas_page_akhir] != undefined &&
                  page_list_array[index_last_true].key ==
                  page_list_array[this.pageActive - 1].key
                ) {
                  page_list_array[this.batas_page_awal].show = false;
                  page_list_array[this.batas_page_akhir].show = true;

                  this.batas_page_awal++;
                  this.batas_page_akhir++;
                } else if (this.pageActive != 1) {
                  if (
                    page_list_array[index_first_true].key ==
                    page_list_array[this.pageActive - 1].key
                  ) {
                    this.batas_page_awal--;
                    this.batas_page_akhir--;

                    page_list_array[this.batas_page_awal].show = true;
                    page_list_array[this.batas_page_akhir].show = false;
                  }
                }

                if (status == "next") {
                  if (
                    page_list_array[index_last_true].key ==
                    page_list_array[this.pageActive - 1].key &&
                    page_list_array[this.batas_page_akhir] == undefined
                  ) {
                    //
                  } else {
                    //
                  }
                } else if (status == "previous" && this.pageActive != 1) {
                  // this.pageActive--; this.offset = page_list_array[this.pageActive - 1].value;
                }

                for (let i = 0; i < page_list_array.length; i++) {
                  this.paginglist.push(page_list_array[i]);
                }

                let pemisahPages = {
                  nama: "...",
                  value: "...",
                  key: "page_number_...",
                  show: true,
                  disabled: true,
                };

                let totalPages = {
                  nama: this.totalPages,
                  value: "0",
                  key: "page_number_total_pages",
                  show: true,
                  disabled: true,
                };
                // console.log(page_list_array[index_last_true]);
                if (
                  page_list_array[index_last_true + 1] &&
                  (page_list_array[index_last_true + 1].show != true ||
                    page_list_array[index_last_true + 1].nama !=
                    totalPages.nama)
                ) {
                  this.paginglist.push(pemisahPages);
                  this.paginglist.push(totalPages);
                }
              }

              // this.previousLastId = response.data.data[1][0].id;

              // let last_index = Object.keys(response.data.data[1]).length - 1;
              // this.NextLastId = response.data.data[1][last_index].id;
            }

            CloseLoading();
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    editRecord(record) {
      // Set the current record to edit mode
      this.currentRecord = Object.assign({}, record);
      this.nilaiMataKuliahGet();
      this.showModal2("modal_mata_kuliah_nilai");

      // this.setRecordwithId('prodi', this.prodiList, 'nama', 'id');
      // this.setRecordwithId('dosen_wali', this.dosenWaliList, 'nama', 'id');
      // // this.setRecordwithId('stambuk', this.stambukList, 'nama', 'id');
      // this.setRecordwithId('waktu_kuliah', this.waktuKuliahList, 'nama', 'id');
    },
    editRecordNilai(record) {
      this.currentRecordNilai = Object.assign({}, record);
      this.showModal2("modal_mata_kuliah_nilai_edit");
    },
    editRecordKomplain(record) {
      this.currentRecordKomplain = Object.assign({}, record);
    },
    editRecordPerbaikan(record) {
      this.currentRecordPerbaikan = Object.assign({}, record);
      if (
        this.currentRecordPerbaikan.uts == -1 &&
        this.currentRecordPerbaikan.uas == -1
      ) {
        this.currentRecordPerbaikan.uts = "";
        this.currentRecordPerbaikan.uas = "";
      }
    },
    editRecordPersetujuan(record) {
      this.currentRecordPersetujuan = Object.assign({}, record);
    },
    editRecordPengaturanKomplain(record) {
      this.currentRecordPengaturanKomplain = Object.assign({}, record);
      this.setRecordwithIdPengaturanKomplain(
        "tahun_ajaran",
        this.tahunAjaranListPengaturanKomplain,
        "nama",
        "id"
      );
      this.setRecordwithIdPengaturanKomplain(
        "semester",
        this.semesterListPengaturanKomplain,
        "nama",
        "id"
      );
    },
    setRecordwithId(field, arraylist, fieldNameArray, valueWantAdd) {
      if (this.currentRecord[field] != null) {
        const index = arraylist.findIndex(
          (record) => record[fieldNameArray] == this.currentRecord[field]
        );
        this.currentRecord[field] = arraylist[index][valueWantAdd];
      } else {
        this.currentRecord[field] = "";
      }
    },
    setRecordwithIdPengaturanKomplain(
      field,
      arraylist,
      fieldNameArray,
      valueWantAdd
    ) {
      if (this.currentRecordPengaturanKomplain[field] != null) {
        const index = arraylist.findIndex(
          (record) =>
            record[fieldNameArray] ==
            this.currentRecordPengaturanKomplain[field]
        );
        this.currentRecordPengaturanKomplain[field] =
          arraylist[index][valueWantAdd];
      } else {
        this.currentRecordPengaturanKomplain[field] = "";
      }
    },
    deleteData() {
      ShowLoading();
      const formData = new FormData();
      //get kehadiran siswa
      this.attendanceRecords = delete this.mahasiswaRecords.nama_lengkap;
      this.attendanceRecords = delete this.mahasiswaRecords.nim_mahasiswa;
      this.attendanceRecords = this.mahasiswaRecords.map((employee) => {
        const { ...record } = employee;
        delete record.nama_lengkap;
        delete record.nim_mahasiswa;
        return record;
      });

      formData.append("id", this.temporaryId);
      formData.append("status_dosen", this.temporaryStatusDosen);
      formData.append("id_jadwal", this.id_jadwal_dipilih);
      formData.append(
        "kehadiran_siswa",
        JSON.stringify(this.attendanceRecords)
      );

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "berita_acara" + "_delete";
      } else {
        this.api = base_url + "berita_acara" + "_delete";
      }
      // Delete a record from the API
      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            // Remove the record from the records list
            const index = this.beritaAcaraRecords.findIndex(
              (record) => record.id === this.temporaryId
            );
            this.beritaAcaraRecords.splice(index, 1);
            CloseLoading();
            AlertBottom(response.data.message);
            this.hideModal2("modal_berita_acara_delete");
          } else {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    deleteDataPengaturanKomplainNilai() {
      ShowLoading();
      const formData = new FormData();
      //get kehadiran siswa
      formData.append("id", this.temporaryId);

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "pengaturan_komplain_nilai" + "_delete";
      } else {
        this.api = base_url + "pengaturan_komplain_nilai" + "_delete";
      }
      // Delete a record from the API
      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            // Remove the record from the records list
            const index = this.recordsPengaturanKomplain.findIndex(
              (record) => record.id === this.temporaryId
            );
            this.recordsPengaturanKomplain.splice(index, 1);
            CloseLoading();
            AlertBottom(response.data.message);
            this.hideModal2("modal_pengaturan_komplain_nilai_delete");
          } else {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    nilaiMataKuliahGet() {
      this.mataKuliahNilaiRecords = [];

      ShowLoading();
      let formData = new FormData();

      formData.append("tahun_ajaran", this.currentRecord.tahun_ajaran);
      formData.append("prodi", this.currentRecord.prodi);
      formData.append("semester", this.currentRecord.semester);
      formData.append("kelas", this.currentRecord.kelas);
      formData.append("mahasiswa", this.currentRecord.mahasiswa);

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + this.url + "_get";
      } else {
        this.api = base_url + this.url + "_get";
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            if (response.data.data != undefined) {
              this.mataKuliahNilaiRecords = response.data.data[1];
            }
            CloseLoading();
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
            this.hideModal2("modal_mata_kuliah_nilai");
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    eksportNilaiMataKuliah() {
      ShowLoading();

      let api = "";
      let formData = new FormData();

      formData.append("tahun_ajaran", this.tahunAjaranFilter);
      formData.append("prodi", this.prodiFilter);
      formData.append("semester", this.semesterFilter);
      formData.append("kelas", this.kelasFilter);
      formData.append("tipe", this.tipe_import);
      formData.append("mata_kuliah", this.mataKuliahFilter);
      api = base_url + this.url + "_eksport";

      axios
        .post(api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            response.data.data = this.addNumberToObjects(response.data.data);
            for (var i = 0; i < response.data.data.length; i++) {
              delete response.data.data[i]["id"];
              delete response.data.data[i]["created_at"];
              delete response.data.data[i]["updated_at"];
            }

            // Create a new workbook
            const wb = XLSX.utils.book_new();
            // Create a new worksheet
            const ws = XLSX.utils.json_to_sheet(response.data.data);
            //get kelas
            const index_kelas = this.kelasList.findIndex(
              (record) => record.id === this.kelasFilter
            );
            const nama_kelas = this.kelasList[index_kelas].nama;
            //get mata kuliah
            const index_mata_kuliah = this.mataKuliahList.findIndex(
              (record) => record.id === this.mataKuliahFilter
            );
            const nama_mata_kuliah =
              this.mataKuliahList[index_mata_kuliah].nama;

            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(wb, ws, nama_kelas);
            // Convert the workbook to an XLSX file and download it
            XLSX.writeFile(
              wb,
              "Template Nilai Kelas " +
              nama_kelas +
              " Mata Kuliah " +
              nama_mata_kuliah +
              ".xlsx"
            );
            CloseLoading();
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    importNilaiMataKuliah() {
      ShowLoading();
      this.ImportNilaiExcel().then((response) => {
        if (response == undefined) {
          console.log(response);
          // } else if (response.message != '') {
          //     AlertPopup("error", "", response.message, 1500, false);
          // }
        } else {
          let data = [];

          for (let i = 0; i < response.message.length; i++) {
            data.push(response.message[i]);
          }

          for (let i = 0; i < data.length; i++) {
            if (data[i].colors == "danger") {
              data.unshift(data[i]);
              data.splice(i, i);
            }
          }
          this.laporanRecords = data;

          this.$refs.btn_laporan_import.click();
          this.index();
        }
      });
    },
    ImportNilaiExcel() {
      return new Promise((resolve, reject) => {
        //export to json
        const file = event.target.file_input.files[0];

        // Create a new file reader
        const reader = new FileReader();

        // Add an event listener to the reader
        reader.addEventListener("load", () => {
          // Parse the XLSX data and convert it to a JSON object
          const data = new Uint8Array(reader.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];

          // Convert the worksheet to a JSON object
          let jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          for (var i = 0; i < jsonData.length; i++) {
            jsonData[i].shift();
          }
          // console.log(jsonData);

          let api = "";
          let formData = new FormData();
          formData.append("url", this.url + "_import");
          formData.append("data", JSON.stringify(jsonData));
          formData.append("tipe", this.tipe_import);
          formData.append("tahun_ajaran", this.tahunAjaranFilter);
          formData.append("prodi", this.prodiFilter);
          formData.append("semester", this.semesterFilter);
          formData.append("kelas", this.kelasFilter);
          formData.append("mata_kuliah", this.mataKuliahFilter);

          if (process.env.NODE_ENV === "development") {
            api = base_url + this.url + "_import";
          } else {
            api = base_url + this.url + "_import";
          }

          axios
            .post(api, formData, this.config)
            .then((response) => {
              if (response.data.status == true) {
                resolve(response.data);
                CloseLoading();
              }

              if (response.data.status == false) {
                AlertPopup("error", "", response.data.message, 1500, false);
                // reject(response.data.message);
              }
            })
            .catch((error) => {
              ErrorConnectionTimeOut(error);
              reject(error);
            });
        });

        // Read the selected file as an array buffer
        reader.readAsArrayBuffer(file);
      });
    },
    updateAttendance(id, value) {
      const index = this.mahasiswaRecords.findIndex(
        (record) => record.id == id
      );
      this.mahasiswaRecords[index].status_kehadiran = value;
    },
    nilaiUpdate() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentRecordNilai).forEach(([key, value]) => {
        formData.append(key, value);
      });

      // for (let [key, value] of formData.entries()) {
      //     console.log(`${key}: ${value}`);
      // }

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + this.url + "_update";
      } else {
        this.api = base_url + this.url + "_update";
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            if (response.data.data != undefined) {
              const index = this.mataKuliahNilaiRecords.findIndex(
                (record) => record.id == response.data.data.id
              );
              this.currentRecordNilai = {};
              this.mataKuliahNilaiRecords[index].uts = response.data.data.uts;
              this.mataKuliahNilaiRecords[index].uas = response.data.data.uas;
              this.mataKuliahNilaiRecords[index].tugas =
                response.data.data.tugas;
            }
            this.hideModal2("modal_mata_kuliah_nilai_edit");
            AlertBottom(response.data.message);
            CloseLoading();
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    finalisasiNilai() {
      ShowLoading();
      let formData = new FormData();
      formData.append("tipe", this.tipe_import);
      formData.append("tahun_ajaran", this.tahunAjaranFilter);
      formData.append("prodi", this.prodiFilter);
      formData.append("semester", this.semesterFilter);
      formData.append("kelas", this.kelasFilter);
      formData.append("id_mata_kuliah", this.mataKuliahFilter);
      formData.append("jenis", this.jenis_finalisasi);

      if (this.finalisasi == "YA") {
        formData.append("value", "FINAL");
      } else {
        formData.append("value", "PENDING");
      }

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + this.url + "_finalisasi";
      } else {
        this.api = base_url + this.url + "_finalisasi";
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {

            // if (this.jenis_finalisasi == 'pegawai') {
            //   this.jenis_finalisasi = 'akademik';
            // }

            // if (this.finalisasi == 'YA') {
            //   this.status_finalisasi_cek['status_finalisasi_' + this.jenis_finalisasi] = 'FINAL';
            // } else {
            //   this.status_finalisasi_cek['status_finalisasi_' + this.jenis_finalisasi] = 'PENDING';
            // }

            this.hideModal2("modal_finalisasi");
            AlertBottom(response.data.message);
            CloseLoading();
            this.index();
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    finalisasiNilaiUASTugas() {
      ShowLoading();
      let formData = new FormData();
      formData.append("tipe", 'TUGAS_UAS_QUIZ_AKTIFITAS_PARTISIPATIF_PROYEK');
      formData.append("tahun_ajaran", this.tahunAjaranFilterInputTugasUAS);
      formData.append("prodi", this.prodiFilterInputTugasUAS);
      formData.append("semester", this.semesterFilterInputTugasUAS);
      formData.append("kelas", this.kelasFilterInputTugasUAS);
      formData.append("id_mata_kuliah", this.mataKuliahFilterInputTugasUAS);
      formData.append("jenis", this.jenis_finalisasi);

      if (this.finalisasi == "YA") {
        formData.append("value", "FINAL");
      } else {
        formData.append("value", "PENDING");
      }

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + this.url + "_finalisasi";
      } else {
        this.api = base_url + this.url + "_finalisasi";
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            CloseLoading();
            this.hideModal2("modal_finalisasi");
            this.inputTugasUAS_index();

          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    receiveDataFromChild(data) {
      this.isShowNavbar = data;
    },
    showModal(modal_name) {
      this.$refs[modal_name].classList.remove("animation-hide-modal");
      this.$refs[modal_name].classList.add("animation-show-modal");
    },
    hideModal(modal_name, close_button_modal) {
      this.$refs[modal_name].classList.remove("animation-show-modal");
      this.$refs[modal_name].classList.add("animation-hide-modal");
      setTimeout(() => {
        this.$refs[close_button_modal].click();
      }, 150);
    },
    showModal2(modal_name) {
      this.$refs[modal_name].classList.remove("animation-hide-modal");
      this.$refs[modal_name].classList.add("animation-show-modal");
      this.$refs[modal_name].classList.add("show");
      this.$refs[modal_name].style.display = "block";

      // this.$refs[modal_name].classList.remove('animation-hide-modal')
      // this.$refs[modal_name].classList.add('animation-show-modal')
    },
    hideModal2(modal_name) {
      this.$refs[modal_name].classList.remove("animation-show-modal");
      this.$refs[modal_name].classList.add("animation-hide-modal");

      setTimeout(() => {
        this.$refs[modal_name].classList.remove("show");
        this.$refs[modal_name].style.display = "none";
      }, 150);
      // this.$refs[modal_name].classList.remove('animation-hide-modal')
      // this.$refs[modal_name].classList.add('animation-show-modal')
    },
    deleteRecord(id, status_dosen) {
      this.temporaryId = id;
      this.temporaryStatusDosen = status_dosen;
    },
    deleteRecordApi() {
      const index = this.records.findIndex(
        (record) => record.id === this.temporaryId
      );
      // this.$refs[this.temporaryId].classList.add('bg-danger');
      setTimeout(() => {
        this.$refs["close_modal_delete"].click();
        this.records.splice(index, 1);
      }, 1000);
    },
    checkAndConvert(nilai) {
      if (typeof nilai === 'string') {
        // Remove spaces and replace comma with dot
        nilai = nilai.trim().replace(',', '.');

        // Try to parse as an integer
        let parsedInt = parseInt(nilai, 10);
        if (!isNaN(parsedInt) && String(parsedInt) === nilai) {
          return parsedInt; // It's a valid integer string
        }

        // Try to parse as a float
        let parsedFloat = parseFloat(nilai);
        if (!isNaN(parsedFloat) && String(parsedFloat) === nilai) {
          return parsedFloat; // It's a valid float string
        }

        // If parsing fails, return an error message or handle it as needed
        return -1;
      } else if (typeof nilai === 'number') {
        // It's already a number (int or float)
        return nilai;
      } else {
        // It's neither a number nor a string
        return -1;
      }
    },
    cekNilaiUASTUGAS(status, status_cek) {
      let nilai_mahasiswa = [['nim_mahasiswa', 'nama_lengkap', 'uas', 'tugas', 'uts', 'quiz', 'aktifitas_partisipatif', 'hasil_proyek', 'tipe']];


      Object.entries(this.recordsInputTugasUAS).forEach(([key, value]) => {
        key;
        const current_nilai = [
          value.nim_mahasiswa,
          value.nama_lengkap,
          value.uas,
          value.tugas,
          value.uts,
          value.quiz,
          value.aktifitas_partisipatif,
          value.hasil_proyek,
          'TUGAS_UAS_QUIZ_AKTIFITAS_PARTISIPATIF_PROYEK',
        ]
        nilai_mahasiswa.push(current_nilai);
      });

      const invalidIndexes = [];
      nilai_mahasiswa.forEach((row, index) => {
        if (index === 0) return; // Skip the header row
        const nim_mahasiswa = row[0];
        const nama_lengkap = row[1];
        const uas = this.checkAndConvert(row[2]);
        const tugas = this.checkAndConvert(row[3]);
        const uts = this.checkAndConvert(row[4]);
        const quiz = this.checkAndConvert(row[5]);
        const aktifitas_partisipatif = this.checkAndConvert(row[6]);
        const hasil_proyek = this.checkAndConvert(row[7]);


        if (uas == '-1' || uas == '0' || tugas == '-1' || tugas == '0' || uts == '-1' || uts == '0' || quiz == '-1' || quiz == '0' || aktifitas_partisipatif == '-1' || aktifitas_partisipatif == '0' || hasil_proyek == '-1' || hasil_proyek == '0' || row[2] < 0 || row[3] < 0 || row[4] < 0 || row[5] < 0 || row[6] < 0 || row[7] < 0) {
          const item_prepare = { status: 'GAGAL', text: nama_lengkap + ' (' + nim_mahasiswa + ') nilai uts:' + row[4] + ', nilai uas:' + row[2] + ' , tugas:' + row[3] + ' , quiz:' + row[5] + ' , aktifitas_partisipatif:' + row[6] + ' , hasil_proyek:' + row[7] }
          invalidIndexes.push(item_prepare);
        }
      });

      if (status != "") {

        this.status_cek_nilai = status;
      }

      if (status == 'finalisasi_dosen') {
        this.status_cek_nilai = 'finalisasi_dosen';
      }

      // console.log(status);
      // console.log(status_cek);
      // console.log(this.status_cek_nilai);
      // console.log('teeest');
      if (invalidIndexes.length > 0 && status_cek == true) {
        this.$refs.btn_laporan_invalid.click();
        this.laporanRecords = invalidIndexes;

      } else {
        if (this.status_cek_nilai == 'finalisasi') {
          this.finalisasiNilaiUASTugas();

        } else if (this.status_cek_nilai == 'finalisasi_dosen') {
          this.importNilaiTugasUAS();

        } else {
          this.importNilaiTugasUAS();
        }
      }






    },
    importNilaiTugasUAS() {
      ShowLoading();
      this.ImportNilaiMultipleTugasUAS().then((response) => {
        if (response == undefined) {
          // console.log(response);
          // } else if (response.message != '') {
          //     AlertPopup("error", "", response.message, 1500, false);
          // }
        } else {
          let data = [];

          for (let i = 0; i < response.message.length; i++) {
            data.push(response.message[i]);
          }



          for (let i = 0; i < data.length; i++) {
            if (data[i].colors == "danger") {
              data.unshift(data[i]);
              data.splice(i, i);
            }
          }
          this.laporanRecords = data;
          // console.log(this.status_cek_nilai);
          if (this.status_cek_nilai == 'finalisasi_dosen') {
            this.finalisasiNilaiUASTugas();
            this.$refs.btn_laporan_import.click();
          } else {

            this.$refs.btn_laporan_import.click();
            this.inputTugasUAS_index();
          }


        }
      });
    },
    ImportNilaiMultipleTugasUAS() {
      return new Promise((resolve, reject) => {

        let nilai_mahasiswa = [['nim_mahasiswa', 'nama_lengkap', 'uas', 'tugas', 'quiz', 'aktifitas_partisipatif', 'hasil_proyek', 'tipe']];


        Object.entries(this.recordsInputTugasUAS).forEach(([key, value]) => {
          key;
          const current_nilai = [
            value.nim_mahasiswa,
            value.nama_lengkap,
            value.uas,
            value.tugas,
            value.quiz,
            value.aktifitas_partisipatif,
            value.hasil_proyek,
            'TUGAS_UAS_QUIZ_AKTIFITAS_PARTISIPATIF_PROYEK',
          ]
          nilai_mahasiswa.push(current_nilai);
        });



        let api = "";
        let formData = new FormData();
        formData.append("data", JSON.stringify(nilai_mahasiswa));
        formData.append("tipe", 'TUGAS_UAS_QUIZ_AKTIFITAS_PARTISIPATIF_PROYEK');
        formData.append("tahun_ajaran", this.tahunAjaranFilterInputTugasUAS);
        formData.append("prodi", this.prodiFilterInputTugasUAS);
        formData.append("semester", this.semesterFilterInputTugasUAS);
        formData.append("kelas", this.kelasFilterInputTugasUAS);
        formData.append("mata_kuliah", this.mataKuliahFilterInputTugasUAS);


        if (process.env.NODE_ENV === "development") {
          api = base_url + "nilai_create_input_nilai_tugas_uas";
        } else {
          api = base_url + "nilai_create_input_nilai_tugas_uas";
        }

        // for (let [key, value] of formData.entries()) {
        //   console.log(`${key}: ${value}`);
        // }

        axios
          .post(api, formData, this.config)
          .then((response) => {
            if (response.data.status == true) {
              resolve(response.data);
              CloseLoading();
            }

            if (response.data.status == false) {
              AlertPopup("error", "", response.data.message, 1500, false);
              // reject(response.data.message);
            }
          })
          .catch((error) => {
            ErrorConnectionTimeOut(error);
            reject(error);
          });
      });



    },
    // finalisasiNilaiTugasUAS() {
    //   ShowLoading();
    //   let formData = new FormData();
    //   formData.append("tipe", 'uas_tugas');
    //   formData.append("tahun_ajaran", this.tahunAjaranFilterInputTugasUAS);
    //   formData.append("prodi", this.prodiFilterInputTugasUAS);
    //   formData.append("semester", this.semesterFilterInputTugasUAS);
    //   formData.append("kelas", this.kelasFilterInputTugasUAS);
    //   formData.append("id_mata_kuliah", this.mataKuliahFilterInputTugasUAS);

    //   if (this.finalisasi == "YA") {
    //     formData.append("value", "FINAL");
    //   } else {
    //     formData.append("value", "PENDING");
    //   }

    //   // for (let [key, value] of formData.entries()) {
    //   //     console.log(`${key}: ${value}`);
    //   // }

    //   if (process.env.NODE_ENV === "development") {
    //     this.api = base_url + this.url + "_finalisasi";
    //   } else {
    //     this.api = base_url + this.url + "_finalisasi";
    //   }

    //   axios
    //     .post(this.api, formData, this.config)
    //     .then((response) => {
    //       if (response.data.status == true) {
    //         this.hideModal2("modal_finalisasi");
    //         AlertBottom(response.data.message);

    //         CloseLoading();
    //         this.inputTugasUAS_index();
    //       }

    //       if (response.data.status == false) {
    //         AlertPopup("error", "", response.data.message, 1500, false);
    //       }
    //     })
    //     .catch((error) => {
    //       ErrorConnectionTimeOut(error);
    //     });
    // },
    // finalisasiDosenNilaiTugasUAS() {
    //   ShowLoading();
    //   let formData = new FormData();
    //   formData.append("tipe", 'status_finalisasi_dosen');
    //   formData.append("tahun_ajaran", this.tahunAjaranFilterInputTugasUAS);
    //   formData.append("prodi", this.prodiFilterInputTugasUAS);
    //   formData.append("semester", this.semesterFilterInputTugasUAS);
    //   formData.append("kelas", this.kelasFilterInputTugasUAS);
    //   formData.append("id_mata_kuliah", this.mataKuliahFilterInputTugasUAS);
    //   if (this.finalisasi == "YA") {
    //     formData.append("value", "FINAL");
    //   } else {
    //     formData.append("value", "PENDING");
    //   }

    //   if (process.env.NODE_ENV === "development") {
    //     this.api = base_url + this.url + "_finalisasi";
    //   } else {
    //     this.api = base_url + this.url + "_finalisasi";
    //   }

    //   axios
    //     .post(this.api, formData, this.config)
    //     .then((response) => {
    //       if (response.data.status == true) {
    //         this.hideModal2("modal_finalisasi");
    //         AlertBottom(response.data.message);

    //         CloseLoading();
    //         this.inputTugasUAS_index();
    //       }

    //       if (response.data.status == false) {
    //         AlertPopup("error", "", response.data.message, 1500, false);
    //       }
    //     })
    //     .catch((error) => {
    //       ErrorConnectionTimeOut(error);
    //     });
    // },
    indexInputNilai() {
      ShowLoading();

      let api = "";
      let formData = new FormData();

      formData.append("tahun_ajaran", this.tahunAjaranFilter);
      formData.append("prodi", this.prodiFilter);
      formData.append("semester", this.semesterFilter);
      formData.append("kelas", this.kelasFilter);
      formData.append("tipe", this.tipe_import);
      formData.append("mata_kuliah", this.mataKuliahFilter);
      api = base_url + this.url + "_eksport";

      axios
        .post(api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {

            if (response.data.data != undefined) {
              this.recordsNilai = response.data.data;
            }

            CloseLoading();
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    importNilai(status_finalisasi) {
      ShowLoading();
      let api = "";
      let formData = new FormData();
      formData.append("url", this.url + "_import");
      const nestedData = [
        ["nim_mahasiswa", "nama_lengkap", "nilai", "tipe"], // Headers
        ...this.recordsNilai.map(item => [item.nim_mahasiswa, item.nama_lengkap, item.nilai, item.tipe])
      ];
      formData.append("data", JSON.stringify(nestedData));
      formData.append("tipe", this.tipe_import);
      formData.append("tahun_ajaran", this.tahunAjaranFilter);
      formData.append("prodi", this.prodiFilter);
      formData.append("semester", this.semesterFilter);
      formData.append("kelas", this.kelasFilter);
      formData.append("mata_kuliah", this.mataKuliahFilter);

      if (process.env.NODE_ENV === "development") {
        api = base_url + this.url + "_import";
      } else {
        api = base_url + this.url + "_import";
      }

      axios
        .post(api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            const data = [];
            for (let i = 0; i < response.data.message.length; i++) {
              data.push(response.data.message[i]);
            }
            for (let i = 0; i < data.length; i++) {
              if (data[i].colors == "danger") {
                data.unshift(data[i]);
                data.splice(i, i);
              }
            }
            this.laporanRecords = data;

            this.$refs.btn_laporan_import.click();
            CloseLoading();
            if (status_finalisasi == undefined) {
              this.index();
            } else {
              this.finalisasi = 'YA'; this.jenis_finalisasi = 'dosen'; this.finalisasiNilai()
            }
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });

    },
  },
  created() {
    this.token = Cookies.get("token");
    this.config = {
      headers: { Authorization: `Bearer ` + this.token },
      timeout: 0,
    };
    //akses
    this.create_akses = menu_akses_cek(this.url2, "create");
    this.update_akses = menu_akses_cek(this.url2, "update");
    this.delete_akses = menu_akses_cek(this.url2, "delete");

    this.index();
    this.hariJadwalList = hariList;
    this.tipe = localStorage.getItem("tipe_user");
  },
};
</script>
